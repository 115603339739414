import { Injectable } from '@angular/core';
import { HistoricalInspectionApi } from '../sdk/services/custom';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  localInspection;
  constructor(private historicalInspectionApi: HistoricalInspectionApi,
  ) {}
  getInspection(id): Observable<any> {
    if (this.localInspection && id === this.localInspection.id) {
      return of(this.localInspection);
    }
    return new Observable<any>((subscriber) => {
      this.historicalInspectionApi.findById(id).subscribe(
        (res) => {
          this.localInspection = res;
          subscriber.next(res);
          subscriber.complete();
        },
      );
    });
  }

  translateTexts(vehicleType) {
    const text =  (vehicleType === 'car') ? 'Automóvil' :
      (vehicleType === 'motorbike') ? 'Moto' :
        (vehicleType === 'bike') ? 'Bicicleta' :
          (vehicleType === '0km') ? '0km' :
            (vehicleType === 'truck') ? 'Camión' :
              (vehicleType === 'bus') ? 'Colectivo' :
                (vehicleType === 'trailer') ? 'Trailer' :
                  (vehicleType === 'semitrailer') ? 'Semitrailer' :
                    (vehicleType === 'caravan') ? 'Casilla Rodante' :
                      (vehicleType === 'tractor') ? 'Tractor' : 'Todos';
    return text;
  }

  /**
   * Get all the related inspection with the 'inspection' param
   * This functionality is only for the inspections that can be grouped by licence Plate
   * @param inspections
   */
  getRelatedIspections(inspection) : Observable<any> {
    const regexLicencePlateCar =
      '^[a-zA-Z]{3}[ ]*[0-9]{3}$|^[a-zA-Z]{2}[ ]*[0-9]{3}[ ]*[a-zA-Z]{2}$';
    if ((inspection.vehicleType === 'car' ||
      inspection.vehicleType === 'motorbike' ||
      inspection.vehicleType === 'track' ||
      inspection.vehicleType === '0km') && inspection.licencePlate &&
      inspection.licencePlate.match(regexLicencePlateCar)) {
      const filter = {
        where: {
          and: [{
            id: { neq: inspection.id },
          }, {
            licencePlate: inspection.licencePlate,
          }],
        },
        fields: {
          id: true,
          licencePlate: true,
          date: true,
          procedureType: true,
        },
      };
      return this.historicalInspectionApi.find(filter);
    }
    return of([]);
  }

}
