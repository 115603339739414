import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot) {
    if (localStorage.getItem('isLoggedin')) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('isLoggedin')) {
      // check if route is restricted by role
      if (childRoute.data.roles && !this.hasRoles(childRoute.data.roles)) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }
      // authorised so return true
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }

  hasRoles(requiredRoles: string[]): boolean {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) {
      const userRoles = currentUser.roles;
      return requiredRoles.filter((role) => {
        return userRoles.filter(userRole => userRole.name === role).length > 0;
      }).length > 0;
    }
    return false;
  }
}
