import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-inspection-sending-data',
  templateUrl: './inspection-sending-data.component.html',
  styleUrls: ['./inspection-sending-data.component.scss'],
  // animations: [routerTransition()]
})
export class InspectionSendingDataComponent implements OnInit {

  inspectionLink;

  public constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService) {
  }

  ngOnInit() {
    this.inspectionLink = this.data.link;
  }

  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success(
      'Link copiado al portapapeles', 'Copiado',
      {
        positionClass: 'toast-bottom-right',
      });
  }
}
