import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { InspectionService } from '../../../shared/services/inspection.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'app-preview-report',
  templateUrl: './preview-report.component.html',
  styleUrls: ['./preview-report.component.scss'],
})
export class PreviewReportComponent implements OnInit {

  public displayedColumns: string[] =
    ['provinceName',  'number', 'percentage'];

  public dataSource: MatTableDataSource<any>;

  barChartOptions = {
    interactivityEnabled: false,
    maintainAspectRatio: false,
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        stacked: true,
      }],
      xAxes: [{
        stacked: true,
      }],
    },
  };

  canvas;
  canvasGeo;
  inspectionNumber;
  filtersCanvas;
  filtersCanvasGeo;

  vehicleType;
  vehicleTypeGeo;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private inspectionService: InspectionService,
  ) { }

  ngOnInit() {
    const canvas = localStorage.getItem('reportCanvas');
    const canvasGeo = localStorage.getItem('reportCanvasGeo');
    const filtersCanvas = localStorage.getItem('filtersCanvas');
    const filtersCanvasGeo = localStorage.getItem('filtersCanvasGeo');
    const inspectionNumber = localStorage.getItem('inspectionNumber');
    const geographicData = localStorage.getItem('dataSourceData');
    this.canvas = JSON.parse(canvas);
    this.canvasGeo = JSON.parse(canvasGeo);
    this.filtersCanvas = JSON.parse(filtersCanvas);
    this.filtersCanvasGeo = JSON.parse(filtersCanvasGeo);
    this.inspectionNumber = JSON.parse(inspectionNumber);
    this.vehicleType = this.inspectionService.translateTexts(filtersCanvas['vehicleType']);
    this.vehicleTypeGeo = this.inspectionService.translateTexts(filtersCanvasGeo['vehicleType']);
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.data = JSON.parse(geographicData);
  }

  cancelPreprint() {
    this.location.back();
  }
  downloading = false;
  downloadPDF() {
    this.downloading = true;
    setTimeout(
      () => {
        window.print();
      },
      500);
  }
}
