import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
declare let require: any;
const exifReader = require('exifreader');
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import sweetalert2 from 'sweetalert2';
import { InspectionService } from '../../../shared/services/inspection.service';
import { GoogleMap } from '@angular/google-maps';
const DEFAULT_ZOOM = 15;

@Component({
  selector: 'app-inspection-more-information',
  templateUrl: './inspection-more-information.component.html',
  styleUrls: ['./inspection-more-information.component.scss'],
})
export class InspectionMoreInformationComponent implements OnInit {

  inspectionId: string;
  inspection;
  pictureName;
  picture;
  ocrData;
  certificationData;
  enabledLens = false;
  lensHeight = null;
  lensWidth = null;
  circularLens = null;
  showTabOne = true;
  exifImage;
  bounds: any = false;
  indexPic;
  pictureAmount;

  markerOptions: google.maps.MarkerOptions = { draggable: false };
  mapOptions: google.maps.MapOptions = {
  };

  @ViewChild('googleMapRef')
    googleMaps : GoogleMap;
  zoom = DEFAULT_ZOOM - 1; // Hack to map zoom works fine
  constructor(
    private activatedRoute: ActivatedRoute,
    private  http: HttpClient,
    private inspectionService: InspectionService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private router: Router) {
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.activatedRoute.params.subscribe(async (params) => {
      this.inspectionId = params.id;
      this.pictureName = params.picName;
      await this.getInspectionData();
    });
  }
  async getInspectionData() {
    this.inspectionService.getInspection(this.inspectionId).subscribe((inspection) => {
      this.inspection = inspection;
      this.pictureAmount = this.inspection.pictures.length;
      let picturesFiltered;
      if (this.inspection) {
        picturesFiltered = this.inspection.pictures.filter((value, index) => {
          if (value.name === this.pictureName) {
            this.indexPic = index;
          }
          return value.name === this.pictureName;
        });
      }
      this.picture = picturesFiltered[0];
      this.loadMap();
      this.picture.latitude = Number(this.picture.latitude);
      this.picture.longitude = Number(this.picture.longitude);
      this.http.get(
        `${this.picture.link}?origin=example.com`, {
          responseType: 'blob' }).subscribe(async (imageBlob) => {
        const imageFile = new File(
          [imageBlob],
          'thumbnail', {
            type: 'jpeg', lastModified: Date.now(),
          });
        const tags = await exifReader.load(imageFile);
        this.exifImage = tags;
      });
      if (this.inspection.imageRecognitionData &&
        this.inspection.imageRecognitionData.ocrData &&
        this.inspection.imageRecognitionData.ocrData.length > 0) {
        const ocrDataFiltered =
          this.inspection.imageRecognitionData.ocrData.filter((value) => {
            return value.pictureName === this.pictureName;
          });
        this.ocrData = ocrDataFiltered[0];
      }
      if (this.inspection.certificationData && this.inspection.certificationData.length > 0) {
        const certificationDataFiltered = this.inspection.certificationData
          .filter((value) => {
            return value.pictureName === this.pictureName;
          });
        this.certificationData = certificationDataFiltered[0];
      }
    });
  }

  loadMap() {
    this.bounds = new window['google'].maps.LatLngBounds();
    this.bounds.extend(
      new window['google'].maps
        .LatLng(this.picture.location.coordinates[1], this.picture.location.coordinates[0]));

    setTimeout(() => {
      this.googleMaps.fitBounds(this.bounds, 0);
      const idleSubscription = this.googleMaps.idle.subscribe(() => {
        if (this.googleMaps.getZoom() > DEFAULT_ZOOM) {
          this.zoom = DEFAULT_ZOOM;
        }
        idleSubscription.unsubscribe();
      });
    },         100);
  }

  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success(
      'Texto copiado al portapapeles',
      'Copiado',
      {
        positionClass: 'toast-bottom-right',
      });
  }
  enableLends() {
    this.enabledLens = true;
    this.lensHeight = 200;
    this.lensWidth = 200;
    this.circularLens = true;
  }
  changeTab(text) {
    if (text === 'tab 1') {
      this.showTabOne = true;
    } else {
      this.showTabOne = false;
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEventDown(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.goToPreviousPicInfo();
    }
    if (event.key === 'ArrowRight') {
      this.goToNextPicInfo();
    }
  }
  goToNextPicInfo() {
    const index = this.indexPic;
    let newIndex;
    if (index === (this.pictureAmount - 1)) {
      newIndex = 0;
    } else {
      newIndex = index + 1;
    }
    const selectedPicture = this.inspection.pictures[newIndex];
    const nextPictureUrl = `/inspections/${this.inspection.id}/pictures/${selectedPicture.name}`;
    this.router.navigateByUrl(nextPictureUrl)
      .catch(() => {
        sweetalert2.fire({
          title: 'Ups! Algo salio mal',
          text: 'Ocurrió un error al acceder a los datos.',
          type: 'error',
          confirmButtonText: 'Salir',
          buttonsStyling: false,
        });
      });
  }

  goToPreviousPicInfo() {
    const index = this.indexPic;
    let newIndex;
    if (index === 0) {
      newIndex = (this.pictureAmount - 1);
    } else {
      newIndex = index - 1;
    }
    const selectedPicture = this.inspection.pictures[newIndex];
    const previousPictureUrl =
      `/inspections/${this.inspection.id}/pictures/${selectedPicture.name}`;
    this.router.navigateByUrl(previousPictureUrl)
      .catch(() => {
        sweetalert2.fire({
          title: 'Ups! Algo salio mal',
          text: 'Ocurrió un error al acceder a los datos.',
          type: 'error',
          confirmButtonText: 'Salir',
          buttonsStyling: false,
        });
      });
  }
}
