/**
 * Created by aderenzis on 02/08/17.
 */
import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';

export class GobalValidators {

  static validarCuit(control: UntypedFormControl): any {
    let cuit = control.value;
    if (!cuit) {
      return {
        invalidCuit: true,
      };
    }
    cuit = cuit.replace(/[^0-9]/g, '');
    if (cuit.length !== 11) {
      return {
        invalidCuit: true,
      };
    }
    let acumulado = 0;
    const digitos = cuit.split('');
    const digito = digitos.pop();

    for (let i = 0; i < digitos.length; i = i + 1) {
      acumulado += +digitos[9 - i] * (2 + (i % 6));
    }

    let verif = 11 - (acumulado % 11);
    if (verif === 11) {
      verif = 0;
    } else if (verif === 10) {
      verif = 9;
    }

    if (digito === verif.toString()) {
      return null;
    }
    return {
      invalidCuit: true,
    };

  }

  static mailFormat(control: UntypedFormControl): any {

    const EMAIL_REGEXP =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (control.value !== '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { incorrectMailFormat: true };
    }

    return null;
  }

  static phonenumber(control: UntypedFormControl): any {
    const phoneno = /^\+?\d{1,3}?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d$/;
    if (control.value.match(phoneno)) {
      return null;
    }
    return { incorrectPhoneFormat: true };
  }
  static url(control: UntypedFormControl): any {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (control.value.match(pattern)) {
      return null;
    }
    return { incorrectUrlFormat: true };
  }
  public static motorbikeLicencePlateValidator(control: AbstractControl): ValidationErrors | null {
    const matricule1 = /^[0-9]{3}[a-zA-Z]{3}$|^[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{3}$/;
    if (control.value && control.value.match(matricule1)) {
      return null;
    }
    return { incorrectMatriculeFormat: true };
  }
  public static carLicencePlateValidator(control: AbstractControl): ValidationErrors | null {
    const matricule1 = /^[a-zA-Z]{3}[0-9]{3}$/;
    const matricule2 = /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$/;
    if (control.value && (control.value.match(matricule1) || control.value.match(matricule2))) {
      return null;
    }
    return { incorrectMatriculeFormat: true };
  }
}
