/* tslint:disable */

declare var Object: any;
export interface CertificationInterface {
  "certificationId": string;
  "certified": boolean;
  "certificationData"?: any;
  "id"?: any;
  "tokenId"?: any;
  token?: any;
}

export class Certification implements CertificationInterface {
  "certificationId": string;
  "certified": boolean;
  "certificationData": any;
  "id": any;
  "tokenId": any;
  token: any;
  constructor(data?: CertificationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Certification`.
   */
  public static getModelName() {
    return "Certification";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Certification for dynamic purposes.
  **/
  public static factory(data: CertificationInterface): Certification{
    return new Certification(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Certification',
      plural: 'Certifications',
      path: 'Certifications',
      idName: 'id',
      properties: {
        "certificationId": {
          name: 'certificationId',
          type: 'string'
        },
        "certified": {
          name: 'certified',
          type: 'boolean'
        },
        "certificationData": {
          name: 'certificationData',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "tokenId": {
          name: 'tokenId',
          type: 'any'
        },
      },
      relations: {
        token: {
          name: 'token',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'tokenId',
          keyTo: 'id'
        },
      }
    }
  }
}
