/* tslint:disable */

declare var Object: any;
export interface ProcedureTypeInterface {
  "code"?: string;
  "internalCode": number;
  "type": string;
  "vehicleType": string;
  "vehicleTypeInternalCode": number;
  "showByDefault": boolean;
  "highlighted"?: boolean;
  "photographableItemIds": any;
  "_pictures"?: any;
  "id"?: any;
  "_inspectionFormModules"?: Array<any>;
  inspectionFormModules?: any[];
}

export class ProcedureType implements ProcedureTypeInterface {
  "code": string;
  "internalCode": number;
  "type": string;
  "vehicleType": string;
  "vehicleTypeInternalCode": number;
  "showByDefault": boolean;
  "highlighted": boolean;
  "photographableItemIds": any;
  "_pictures": any;
  "id": any;
  "_inspectionFormModules": Array<any>;
  inspectionFormModules: any[];
  constructor(data?: ProcedureTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProcedureType`.
   */
  public static getModelName() {
    return "ProcedureType";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ProcedureType for dynamic purposes.
  **/
  public static factory(data: ProcedureTypeInterface): ProcedureType{
    return new ProcedureType(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ProcedureType',
      plural: 'ProcedureTypes',
      path: 'ProcedureTypes',
      idName: 'id',
      properties: {
        "code": {
          name: 'code',
          type: 'string'
        },
        "internalCode": {
          name: 'internalCode',
          type: 'number'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "vehicleType": {
          name: 'vehicleType',
          type: 'string'
        },
        "vehicleTypeInternalCode": {
          name: 'vehicleTypeInternalCode',
          type: 'number'
        },
        "showByDefault": {
          name: 'showByDefault',
          type: 'boolean'
        },
        "highlighted": {
          name: 'highlighted',
          type: 'boolean'
        },
        "photographableItemIds": {
          name: 'photographableItemIds',
          type: 'any'
        },
        "_pictures": {
          name: '_pictures',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "_inspectionFormModules": {
          name: '_inspectionFormModules',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        inspectionFormModules: {
          name: 'inspectionFormModules',
          type: 'any[]',
          model: '',
          relationType: 'embedsMany',
                  keyFrom: '_inspectionFormModules',
          keyTo: 'id'
        },
      }
    }
  }
}
