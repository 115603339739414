import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'photofied-triunfo-company-web';

  constructor(public router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer) {
    // Custom icons registration
    // Trash icon
    this.matIconRegistry.addSvgIcon(
      'custom_trash',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/svg/trash.svg'),
    );
    // Edit icon
    this.matIconRegistry.addSvgIcon(
      'custom_edit',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/svg/edit.svg'),
    );
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-57QCVQ068L', {
          page_path: event.urlAfterRedirects,
        },
        );
      }
    },
    );
  }
  ngOnInit() {
  }
}
