import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthGuard } from '../../../shared/guard';
import { SidebarService } from '../../../shared/services/sidebar.service';
declare let require;
const pjson = require('./../../../../../package.json');

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  version = pjson.version;

  isActive = false;
  showMenu = '';
  pushRightClass = 'push-right';
  roles: string[];
  // companyUser: CompanyUser;
  collapse: boolean;

  constructor(public router: Router,
    private authGuard: AuthGuard,
    private sidebarService: SidebarService) {

    this.router.events.subscribe((val) => {
      if (
        val instanceof NavigationEnd &&
        window.innerWidth <= 992 &&
        this.isToggled()
      ) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit() {
    // this.companyUser = JSON.parse(localStorage.getItem('user'));
    this.collapse = !!localStorage.getItem('collapseSidebar');
    this.collapseSidebar(this.collapse);
    this.roles = JSON.parse(localStorage.getItem('roles'));
  }

  eventCalled() {
    this.isActive = !this.isActive;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  rltAndLtr() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle('rtl');
  }

  onLoggedout() {
    localStorage.removeItem('isLoggedin');
  }

  hasRoles(roles): boolean {
    return this.authGuard.hasRoles(roles);
  }
  collapseSidebar(collapse: boolean) {
    if (!collapse) {
      this.collapse = false;
      this.sidebarService.setStatusSidebar('open');
      localStorage.removeItem('collapseSidebar');
    } else {
      this.collapse = true;
      localStorage.setItem('collapseSidebar', 'true');
      this.sidebarService.setStatusSidebar('close');
    }
  }
}
