import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';


@Injectable({
  providedIn: 'root',
})
export class SmartAnalyzerService {

  apiUrl: string;
  BAD_QUALITY_THRESHOLDS: any;

  constructor(
    private http: HttpClient,
    environmentService: EnvironmentService,
  ) {
    this.apiUrl = `${environmentService.get('apiUrl')}/api/v1/public/FeatureFlags`;
    if (!this.BAD_QUALITY_THRESHOLDS) {
      this.getAnalysisThresholds();
    }
  }



  warningExists(group) {
    const picturesWithWarning = group.pictures.filter((picture)=>{
      return (picture.recognitionInfo && picture.recognitionInfo.hasWarning);
    });
    return picturesWithWarning && picturesWithWarning.length;
  }

  getWarning(smartAnalyzerInfo) {
    let ret = '';
    if (this.BAD_QUALITY_THRESHOLDS) {
      if (smartAnalyzerInfo.pictureFromScreen > this.BAD_QUALITY_THRESHOLDS.minimumConfidencePictureFromScreen) {
        smartAnalyzerInfo.hasWarning = true;
        ret += 'Fotografía de una pantalla.<br>';
      }
      if (smartAnalyzerInfo.badLighting > this.BAD_QUALITY_THRESHOLDS.minimumConfidenceBadLighting) {
        smartAnalyzerInfo.hasWarning = true;
        if (ret.length) {
          ret += ' ';
        }
        ret += 'Mala iluminación.<br>';
      }
      if (smartAnalyzerInfo.goodQuality < this.BAD_QUALITY_THRESHOLDS.minimumConfidenceGoodQuality) {
        smartAnalyzerInfo.hasWarning = true;
        if (ret.length) {
          ret += ' ';
        }
        ret += 'Mala Calidad.<br>';
      }
      if (!ret) {
        smartAnalyzerInfo.hasWarning = false;
      }
    }


    return ret;
  }
  getAnalysisThresholds(): Promise<boolean> {
    return new Promise<boolean>(
      (resolve) => {
        this.http.get(`${this.apiUrl}?filter[where][name]=FF_SPA_BAD_QUALITY_THRESHOLDS`).subscribe(
          (res: any) => {
            if (res && res.length > 0) {
              this.BAD_QUALITY_THRESHOLDS = res[0].activationData;
              resolve(this.BAD_QUALITY_THRESHOLDS);
            }
          },
          (err) => {
            console.error(err);
            return resolve(true);
          },
        );
      });
  }
}
