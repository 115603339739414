import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoricalInspectionApi, InspectionReportApi } from '../../../shared/sdk/services/custom';
import { HttpClient } from '@angular/common/http';
import * as lottie from '../../../../assets/lottie/lottie.js';
import { ToastrService } from 'ngx-toastr';
import { GoogleMap } from '@angular/google-maps';

declare let require: any;
const exifReader = require('exifreader');

const DEFAULT_ZOOM = 15;

@Component({
  selector: 'app-inspection-detail',
  templateUrl: './inspection-detail-one-page.component.html',
  styleUrls: ['./inspection-detail-one-page.component.scss'],
})
export class InspectionDetailOnePageComponent implements OnInit {

  inspection: any;
  vehicleType;

  bounds: any = false;

  pictures = [];
  inspectionId: string;
  pictureName;
  picture;
  ocrData;
  enabledLens = false;
  lensHeight = null;
  lensWidth = null;
  circularLens = null;
  showTabOne = true;
  exifImage;
  anim;
  expired = false;
  inspectionNotExist = false;
  zoom = DEFAULT_ZOOM - 1;
  @ViewChild('googleMapRef')
    googleMaps: GoogleMap;

  @ViewChildren(GoogleMap)
    googleMapsElements: GoogleMap[];
  constructor(
    private activatedRoute: ActivatedRoute,
    private historicalInspectionApi: HistoricalInspectionApi,
    private inspectionReportApi: InspectionReportApi,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private toastr: ToastrService,
  ) {
    this.inspectionId = this.route.snapshot.paramMap.get('id');
  }
  ngOnInit() {
    this.getInspection();
    const animData = {
      container: document.getElementById('lottie'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: 'assets/animations/ended.json',
    };
    this.anim = lottie.loadAnimation(animData);
    window.addEventListener('scroll', function () {
      const scrollPosition = window.scrollY;
      const triggerPosition = 500;
      if (scrollPosition > triggerPosition) {
        document.getElementById('toTop').style.display = 'grid';
      } else {
        document.getElementById('toTop').style.display = 'none';
      }
    });
  }
  getInspection() {
    this.inspectionReportApi.findById(this.inspectionId).subscribe(
      (inspection) => {
        this.inspection = inspection;
        this.expired = this.hasExpired();
        this.getImagesInformation();
        localStorage.setItem('inspection', JSON.stringify(this.inspection));
        this.loadMap();
      },
      () => {
        this.inspectionNotExist = true;
      },
    );
  }
  loadMap() {
    this.bounds = new window['google'].maps.LatLngBounds();
    for (const mm of this.inspection.pictures) {
      this.bounds.extend(
        new window['google'].maps
          .LatLng(mm.location.coordinates[1], mm.location.coordinates[0]));
    }
    setTimeout(() => {
      this.googleMaps.fitBounds(this.bounds, 0);
      const idleSubscription = this.googleMaps.idle.subscribe(() => {
        if (this.googleMaps.getZoom() > DEFAULT_ZOOM) {
          this.zoom = DEFAULT_ZOOM;
        }
        idleSubscription.unsubscribe();
      });
    }, 100);
    setTimeout(() => {
      this.googleMapsElements.forEach((googleMapElement: any) => {
        const name = googleMapElement._elementRef.nativeElement.id;
        if (name) { // It is a individual map
          const picture = this.inspection.pictures.find(picture => picture.name === name);
          this.bounds = new window['google'].maps.LatLngBounds();
          this.bounds.extend(
            new window['google'].maps
              .LatLng(picture.location.coordinates[1], picture.location.coordinates[0]));
          googleMapElement.fitBounds(this.bounds, 0);
          const idleSubscription = googleMapElement.idle.subscribe(() => {
            if (googleMapElement.getZoom() > DEFAULT_ZOOM) {
              this.zoom = DEFAULT_ZOOM;
            }
            idleSubscription.unsubscribe();
          });
        }
      });

    }, 100);

  }
  searchDamageGlass() {
    if (this.inspection.inspectionForm.glasses) {
      return this.inspection.inspectionForm.glasses.find(x => x.damaged > 0);
    }
    return false;
  }
  getImagesInformation() {
    this.inspection.pictures.forEach((picture) => {
      this.http.get(
        `${picture.link}?origin=example.com`, {
          responseType: 'blob',
        }).subscribe(async (imageBlob) => {
        const imageFile = new File(
          [imageBlob],
          'thumbnail', {
            type: 'jpeg', lastModified: Date.now(),
          });
        const tags = await exifReader.load(imageFile);
        picture.exifImage = tags;
        // Getting certification data
        if (this.inspection.certificationData &&
          this.inspection.certificationData.length > 0) {
          const certificationDataFiltered =
            this.inspection.certificationData.filter((value) => {
              return value.pictureName === picture.name;
            });
          picture.certificationData = certificationDataFiltered[0];
        }
      });
    });
  }
  changeTab(text) {
    if (text === 'tab 1') {
      this.showTabOne = true;
    } else {
      this.showTabOne = false;
    }
  }
  enableLends() {
    this.enabledLens = true;
    this.lensHeight = 200;
    this.lensWidth = 200;
    this.circularLens = true;
  }
  hasExpired() {
    const unixTokenCreation = new Date(this.inspection.creationDateReport).getTime() / 1000;
    const unixNow = Date.now() / 1000;
    const unixTokenExpiration = unixTokenCreation + this.inspection.ttl;
    let res;
    if (unixNow > unixTokenExpiration) {
      res = true;
    } else {
      res = false;
    }
    return res;
  }
  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success(
      'Texto copiado al portapapeles',
      'Copiado',
      {
        positionClass: 'toast-bottom-right',
      });
  }

  scrollTo(selector: string) {
    const element = document.querySelector(`#${selector}`);
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: y - 80,
        behavior: 'smooth',
      });
    }
  }
}
