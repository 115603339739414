import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AccessoryPictureApi,
  ClientApi,
  InspectionApi, ProcedureTypeApi,
  SettingApi,
  VehiclePictureApi,
} from '../../../shared/sdk/services/custom/index';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { InspectionSendingDataComponent } from
  './inspection-sending-data/inspection-sending-data.component';
import { HttpHeaders } from '@angular/common/http';
import { GobalValidators } from 'src/app/validators/gobalValidators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './inspection-generator.component.html',
  styleUrls: ['./inspection-generator.component.scss'],
  // animations: [routerTransition()]
})
export class InspectionGeneratorComponent implements OnInit {

  myForm: FormGroup;
  user: any; // Photographer

  id: string;
  submitAttempt: boolean;
  loadingPage: boolean;

  coverageTypes;
  selectedCoverageTypes;

  accessories = [
    { name: 'rim', displayName: 'Llantas de aleación', selected: false, type: 'accesory' },
    { name: 'gnc', displayName: 'Oblea de GNC', selected: false, type: 'accesory' },
    { name: 'gncEquipment', displayName: 'Equipo de GNC', selected: false, type: 'accesory' },
  ];

  incidentPictures = [
    {
      name: 'damage',
      type: 'vehicle',
      displayName: 'Daños en el Vehiculo',
      selected: false,
    },
    {
      name: 'driverLicenceFront',
      type: 'document',
      displayName: 'Licencia de conducir - Frente',
      selected: false,
    },
    {
      name: 'driverLicenceBack',
      type: 'document',
      displayName: 'Licencia de conducir - Dorso',
      selected: false,
    },
    {
      name: 'vehicleIdFront',
      type: 'document',
      displayName: 'Cedula vehicular - Frente',
      selected: false,
    },
    {
      name: 'vehicleIdBack',
      type: 'document',
      displayName: 'Cedula Vehicular - Dorso',
      selected: false,
    },
    {
      name: 'policeReport',
      type: 'document',
      displayName: 'Denuncia policial',
      selected: false,
    },
    {
      name: 'estimate',
      type: 'document',
      displayName: 'Presupuesto',
      selected: false,
    },
    {
      name: 'cbu',
      type: 'document',
      displayName: 'Constacia de CBU',
      selected: false,
    },
  ];

  inspectionLink;
  filteredAccessories;
  public constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private inspectionApi: InspectionApi,
    private procedureTypeApi: ProcedureTypeApi,
  ) {
  }

  ngOnInit() {
    this.loadingPage = true;
    this.myForm = this.formBuilder.group({
      name: ['', Validators.compose(
        [Validators.maxLength(30),
        Validators.pattern('[\' A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙñÑ.-]*'),
        Validators.required])],
      vehicleType: [{ value: 'car' }, Validators.required],
      inspectionType: ['', Validators.required],
      coverageType: [''],
      licencePlate: ['', Validators.required],
    });
    this.updateLicencePlateValidator();
    this.procedureTypeApi.find({ order: 'code ASC' }).subscribe(
      (res) => {
        this.coverageTypes = res;
      },
    );
    this.loadingPage = false;
    this.onFormChanges();
  }
  updateLicencePlateValidator() {
    this.myForm.get('vehicleType').valueChanges.subscribe(vehicleType => {
      const licencePlateControl = this.myForm.get('licencePlate');
      // Clear existing validators
      licencePlateControl.clearValidators();
      // Set new validators based on vehicle type
      if (vehicleType !== 'bike' && vehicleType !== '0km') {
        if (vehicleType === 'motorbike') {
          licencePlateControl.setValidators([Validators.required, GobalValidators.motorbikeLicencePlateValidator]);
        } else {
          licencePlateControl.setValidators([Validators.required, GobalValidators.carLicencePlateValidator]);
        }
      } else {
        licencePlateControl.setValidators([Validators.required]);
      }


      // Revalidate with new validators
      licencePlateControl.updateValueAndValidity();
    });
  }
  copyToClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success(
      'Api-Key copiada al portapapeles', 'Copiado',
      {
        positionClass: 'toast-bottom-right',
      });
  }

  onFormChanges(): void {
    this.myForm.get('vehicleType').valueChanges.subscribe((vehicleType) => {
      this.selectedCoverageTypes =
        this.coverageTypes.filter(coverageType => coverageType.vehicleType === vehicleType);
    });
  }

  generatePreviousInspection() {
    const inspectionData = {
      transactionId: `${Math.floor(1000 + Math.random() * 9000)}-${this.myForm.value.licencePlate}`,
      agentCode: '-',
      licencePlate: (this.myForm.value.vechicleType === 'bike') ?
        '' : this.myForm.value.licencePlate,
      tipoDoc: 'DNI',
      numeroDoc: (this.myForm.value.vechicleType === 'bike') ?
        this.myForm.value.licencePlate : '0',
      procedureType: 'inspection',
      vehicleType: this.myForm.value.vehicleType,
      clientName: this.myForm.value.name,
      pictures: [],
      ttl: 259200, // 3 days in seconds
      inspectionForm: [],
    };
    this.myForm.value.coverageType._pictures.forEach((picture) => {
      const pictureData = {
        displayName: (picture.displayName) ? picture.displayName : '-',
        name: picture.name,
        type: picture.type,
      };
      inspectionData.pictures.push(pictureData);
    });
    this.accessories.forEach((picture) => {
      if (picture.selected) {
        const pictureData = {
          displayName: (picture.displayName) ? picture.displayName : '-',
          name: picture.name,
          type: picture.type,
        };
        inspectionData.pictures.push(pictureData);
      }
    });
    inspectionData.inspectionForm = this.myForm.value.coverageType._inspectionFormModules;
    this.generateInspection(inspectionData);
  }
  generateIncident() {
    const inspectionData = {
      transactionId: `${this.myForm.value.estimateNumber}-${this.myForm.value.licencePlate}`,
      agentCode: '-',
      licencePlate: (this.myForm.value.vechicleType === 'bike') ?
        '' : this.myForm.value.licencePlate,
      tipoDoc: 'DNI',
      numeroDoc: (this.myForm.value.vechicleType === 'bike') ?
        this.myForm.value.licencePlate : '0',
      procedureType: 'incident',
      vehicleType: this.myForm.value.vehicleType,
      clientName: this.myForm.value.name,
      pictures: this.incidentPictures.filter(picture => picture.selected),
      ttl: 259200, // 3 days in seconds
      inspectionForm: [],
    };
    this.generateInspection(inspectionData);
  }
  generateInspection(inspectionData) {
    const myCustomHeaders = () => {
      return new HttpHeaders({
        'Api-Key': environment.apiKey,
      });
    };
    this.inspectionApi.createInspection({}, inspectionData, myCustomHeaders).subscribe((res: any) => {
      this.dialog.open(InspectionSendingDataComponent, {
        data: { link: res.link },
        height: '300px',
        width: '600px',
      });
    });
  }
  resetSelection() {
    this.myForm.get('coverageType').setValue(null);
  }
  checkPictures() {
    return !this.incidentPictures.filter(picture => picture.selected).length;
  }
  checkAccessories() {
    const pictures = this.myForm.value.coverageType._pictures;
    const accessories = this.accessories;
    const filteredAccessories = accessories.filter(accessory => {
      return !pictures.some(picture => picture.name === accessory.name);
    });
    this.filteredAccessories = filteredAccessories;
  }
}
