/* tslint:disable */

declare var Object: any;
export interface ImageRecognitionInterface {
  "imageRecognitionId": string;
  "recognized": boolean;
  "imageRecognitionData"?: any;
  "id"?: any;
  "tokenId"?: any;
  token?: any;
}

export class ImageRecognition implements ImageRecognitionInterface {
  "imageRecognitionId": string;
  "recognized": boolean;
  "imageRecognitionData": any;
  "id": any;
  "tokenId": any;
  token: any;
  constructor(data?: ImageRecognitionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ImageRecognition`.
   */
  public static getModelName() {
    return "ImageRecognition";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ImageRecognition for dynamic purposes.
  **/
  public static factory(data: ImageRecognitionInterface): ImageRecognition{
    return new ImageRecognition(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ImageRecognition',
      plural: 'ImageRecognitions',
      path: 'ImageRecognitions',
      idName: 'id',
      properties: {
        "imageRecognitionId": {
          name: 'imageRecognitionId',
          type: 'string'
        },
        "recognized": {
          name: 'recognized',
          type: 'boolean'
        },
        "imageRecognitionData": {
          name: 'imageRecognitionData',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "tokenId": {
          name: 'tokenId',
          type: 'any'
        },
      },
      relations: {
        token: {
          name: 'token',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'tokenId',
          keyTo: 'id'
        },
      }
    }
  }
}
