import {
  AfterViewInit, Component, ElementRef,
  EventEmitter, OnInit, ViewChild,
} from '@angular/core';
import sweetalert2 from 'sweetalert2';
import { HistoricalInspectionApi, ProvinceApi } from '../../../shared/sdk/services/custom/index';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoricalInspection } from '../../../shared/sdk/models';
import { fromEvent, Observable } from 'rxjs';
import * as _ from 'lodash';
import {
  WorkBook, WorkSheet,
  writeFileXLSX as writeFile, utils,
} from 'xlsx';

import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
} from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { SmartAnalyzerService } from '../../../shared/services/smart-analyzer-service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-inspections-list',
  templateUrl: './inspections-list.component.html',
  styleUrls: ['./inspections-list.component.scss'],
})
export class InspectionsListComponent implements OnInit, AfterViewInit {

  // table configuration
  public displayedColumns: string[] =
    ['licencePlate', 'vehicleType',
      'confidence', 'province',
      'insuranceAgentCode', 'date', 'procedureType'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 25, 50];
  elementsNumber: number;
  inspections: HistoricalInspection[];

  slideChangeObserver;
  downloadLoading;

  @ViewChild('removeTokenConfirmationTemplate', { static: true })
  public removeTokenConfirmationTemplate: ElementRef;
  @ViewChild('agentSearchInput', { static: true }) agentCodeInput: ElementRef;
  @ViewChild('transactionIDInput', { static: true }) transactionIDInput: ElementRef;
  @ViewChild('licencePlateSearchInput', { static: true }) licencePlateSearchInput: ElementRef;

  // Filters
  initDate: Date = moment().startOf('day').toDate();
  endDate: Date;
  agentCode: string;
  transactionId: string;
  licencePlate: string;

  vehicleType = 'all';
  minValueSlide = 0;
  maxValueSlide = 100;
  slidersRefresh: EventEmitter<void> = new EventEmitter<void>();
  public dataSource: MatTableDataSource<any>;
  firstLoad = false;
  // others
  isLoadingList: boolean;
  isSearching: boolean;
  inspectionsSeen;
  zone;
  provinces = [];
  procedureType = 'all';
  params;

  isAdmin;
  origin = 'all';
  aiFilter = 'all';
  status: any;
  public radarChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    scale: {
      ticks: {
        maxTicksLimit: 3,
      },
    },
  };
  public radarChartLabels: string[] = [
    'Encuadre', 'Iluminacion', 'Vehiculo detectado', 'Calidad',
    'Fotografia real',
  ];

  public radarChartData = [];
  public spaObj;

  public radarChartType: string = 'radar';
  colors = [
    'rgb(154, 142, 241,0.2)', 'rgb(140, 37, 129,0.2)',
    'rgb(121, 212, 116,0.2)', 'rgb(224, 170, 64,0.2)',
    'rgb(200, 70, 41,0.2)', 'rgb(84, 153, 70,0.2)',
    'rgb(100, 100, 255,0.2)', 'rgb(255, 100, 100,0.2)',
    'rgb(100, 255, 100,0.2)', 'rgb(255, 255, 100,0.2)',
    'rgb(100, 255, 255,0.2)',
  ];

  borderColors = [
    'rgb(154, 142, 241)', 'rgb(140, 37, 129)',
    'rgb(121, 212, 116)', 'rgb(224, 170, 64)',
    'rgb(200, 70, 41)', 'rgb(84, 153, 70)',
    'rgb(100, 100, 255)', 'rgb(255, 100, 100)',
    'rgb(100, 255, 100)', 'rgb(255, 255, 100)',
    'rgb(100, 255, 255)',
  ];
  showChart;
  constructor(
    private historicalInspectionApi: HistoricalInspectionApi,
    private provinceApi: ProvinceApi,
    private route: ActivatedRoute,
    private router: Router,
    private smartAnalyzerService: SmartAnalyzerService,
    private toastr: ToastrService) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.isAdmin =
      !!user.roles.filter(role => role.name === 'admin' || role.name === 'superadmin').length;
    this.displayedColumns.push('status');
    if (this.isAdmin) {
      this.displayedColumns.push('goodQuality');
      this.displayedColumns.push('origin');
    }
    this.displayedColumns.push('AI');
    this.isLoadingList = true;
    this.firstLoad = true;
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
    this.inspectionsSeen =
      localStorage.getItem('inspectionsSeen') ?
        JSON.parse(localStorage.getItem('inspectionsSeen')) : [];
    await this.createSpaObj();
    this.getFiltersByURL();
    this.loadHistoricalInspections();
    this.loadListenerTerm();

    this.loadProvinces();

  }

  /**
   * Listen the input for search by term.
   */
  loadListenerTerm() {
    this.configureDebounce(this.transactionIDInput.nativeElement);
    this.configureDebounce(this.agentCodeInput.nativeElement);
    this.configureDebounce(this.licencePlateSearchInput.nativeElement);
  }
  configureDebounce(nativeElement) {
    fromEvent(nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      filter(res => (res.length > 1 || res.length === 0)),
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.isSearching = true;
      this.loadHistoricalInspections();
    });
  }

  /**
   * get the query string params
   */
  getFiltersByURL() {
    if (this.params['aiFilter']) {
      this.aiFilter = this.params['aiFilter'];
    }
    if (this.params['initDate']) {
      this.initDate = new Date(this.params['initDate']);
    }
    if (this.params['endDate']) {
      this.endDate = new Date(this.params['endDate']);
    }
    if (this.params['searchText']) {
      this.agentCode = this.params['searchText'];
    }
    if (this.params['vehicleType']) {
      this.vehicleType = this.params['vehicleType'];
    }
    if (this.params['zone']) {
      this.zone = this.params['zone'];
    }
    if (this.params['procedureType']) {
      this.procedureType = this.params['procedureType'];
    }
    if (this.params['minValueSlide']) {
      this.minValueSlide = Number(this.params['minValueSlide']);
    }
    if (this.params['maxValueSlide']) {
      this.maxValueSlide = Number(this.params['maxValueSlide']);
    }
    if (this.params['pageSize']) {
      this.pageSize = Number(this.params['pageSize']);
    }
    if (this.params['pageIndex']) {
      this.pageIndex = Number(this.params['pageIndex']);
    }
    if (this.params['licencePlate']) {
      this.licencePlate = this.params['licencePlate'];
    }

  }
  /**
   * get the provinces from server
   */
  loadProvinces() {
    this.provinceApi.find(({ fields: { name: true } })).subscribe(
      (provinces) => {
        this.provinces = provinces;
      },
      (error1) => {
        console.error(error1);
        sweetalert2.fire({
          title: 'Ups! Algo salio mal',
          text: 'Ocurrió un error al obtener las provincias.',
          type: 'error',
          confirmButtonClass: 'btn btn-primary',
          confirmButtonText: 'Salir',
          buttonsStyling: false,
        });
      });
  }

  /**
   * get the inspections from server, using the filters and paginations
   */
  loadHistoricalInspections() {
    this.isLoadingList = true;
    const filter = {
      where: this.configureWhereFilter(),
      fields: {
        id: true,
        transactionId: true,
        licencePlate: true,
        vehicleType: true,
        confidence: true,
        agentCode: true,
        inspectionForm: true,
        date: true,
        pictures: true,
        imageRecognitionData: true,
        successfulMsgList: true,
        province: true,
        procedureType: true,
        isSuccessful: true,
        failedMsgList: true,
      },
      limit: this.pageSize,
      skip: this.pageIndex * this.pageSize,
      order: 'date DESC',
    };
    if (!filter.where) {
      delete filter.where;
    }
    this.historicalInspectionApi.count(filter.where).subscribe((size) => {
      this.historicalInspectionApi.find(filter).subscribe(
        (dataList) => {
          this.translateTexts(dataList).then((translatedList) => {
            this.inspections = translatedList;
            this.inspections.map((inspection: any) => {
              const found = this.inspectionsSeen.find(e => inspection.id === e);
              if (found) {
                inspection.visited = true;
              }
            });
            this.containRelatedIspections(this.inspections);
            this.dataSource.data = this.inspections;
            setTimeout(() => {
              this.elementsNumber = size.count;
            });
            this.isLoadingList = false;
            this.isSearching = false;
            this.addFiltersToURL();
          }).catch((e) => {
            console.error(e);
            sweetalert2.fire({
              title: 'Ups! Algo salio mal',
              text: 'Ocurrió un error al procesar la información.',
              type: 'error',
              confirmButtonClass: 'btn btn-primary',
              confirmButtonText: 'Salir',
              buttonsStyling: false,
            });
          });
        },
        () => {
          sweetalert2.fire({
            title: 'Ups! Algo salio mal',
            text: 'Ocurrió un error al obtener las inspecciones.',
            type: 'error',
            confirmButtonClass: 'btn btn-primary',
            confirmButtonText: 'Salir',
            buttonsStyling: false,
          });
        });
    });
  }
  /**
   * update the filters in query string
   */
  addFiltersToURL() {
    this.router.navigate(
      [], {
        relativeTo: this.route,
        queryParams: {
          initDate: this.initDate,
          endDate: this.endDate,
          searchText: this.agentCode,
          vehicleType: this.vehicleType,
          zone: this.zone,
          procedureType: this.procedureType,
          minValueSlide: this.minValueSlide,
          maxValueSlide: this.maxValueSlide,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
          licencePlate: this.licencePlate,
          aiFilter: this.aiFilter,
        },
        queryParamsHandling: 'merge',
      });
  }

  /**
   * Assign the value 'hasRelatedInspection' to each inspection in 'inspections' params
   * hasRelatedInspection is setted in true when contain 2 or more inspections,
   * else hasRelatedInspection will be false
   * This functionality is only for the inspections that can be grouped by licence Plate
   * @param inspections
   */
  containRelatedIspections(inspections) {
    const groupIds = [];
    const regexLicencePlateCar =
      '^[a-zA-Z]{3}[ ]*[0-9]{3}$|^[a-zA-Z]{2}[ ]*[0-9]{3}[ ]*[a-zA-Z]{2}$';
    inspections.forEach((inspection) => {
      if (inspection.vehicleType === 'car' ||
        inspection.vehicleType === 'motorbike' ||
        inspection.vehicleType === 'track' ||
        inspection.vehicleType === '0km') {
        if (inspection.licencePlate && inspection.licencePlate.match(regexLicencePlateCar)) {
          groupIds.push(inspection.licencePlate);
        }
      }
      inspection.showAIchart = inspection.pictures.some((picture) => {
        return picture.alreadyAnalyzed && picture.analyzeResponse; 
      });
    });

    const filter = {
      where: {
        licencePlate: { inq: groupIds },
      },
      fields: {
        licencePlate: true,
      },
    };
    this.historicalInspectionApi.find(filter).subscribe(
      (dataList) => {
        const groupedData = _.groupBy(dataList, (data) => {
          return data.licencePlate;
        });
        inspections.forEach((inspection) => {
          if (groupedData[inspection.licencePlate]
            && groupedData[inspection.licencePlate].length > 1) {
            inspection.hasRelatedInspection = true;
          }
        });
      });
  }

  /**
   * Redirect to the details page usign the inspectionId params,
   * and set the inspection as visited in localstorage
   * @param inspectionId
   * @param visited
   */
  goToDetails(inspectionId, visited?) {
    if (!visited) {
      this.inspectionsSeen.push(inspectionId);
      localStorage.setItem('inspectionsSeen', JSON.stringify(this.inspectionsSeen));
    }
  }

  /**
   * Apply the traduction in the vehicleTypeTraduction property for each inspection
   * in the inspection list filteredInspections give by params
   * @param filteredInspections
   */
  translateTexts(filteredInspections): Promise<any> {
    const promise = new Promise((resolve) => {
      filteredInspections.forEach((inspection, index) => {
        inspection.position = index;
        inspection.vehicleTypeTraduction = (inspection.vehicleType === 'car') ? 'Automóvil' :
          (inspection.vehicleType === 'motorbike') ? 'Moto' :
            (inspection.vehicleType === 'bike') ? 'Bicicleta' :
              (inspection.vehicleType === '0km') ? '0km' :
                (inspection.vehicleType === 'truck') ? 'Camión' :
                  (inspection.vehicleType === 'bus') ? 'Colectivo' :
                    (inspection.vehicleType === 'trailer') ? 'Trailer' :
                      (inspection.vehicleType === 'semitrailer') ? 'Semitrailer' :
                        (inspection.vehicleType === 'caravan') ? 'Casilla Rodante' :
                          (inspection.vehicleType === 'tractor') ? 'Tractor' : 'Sin Definir';

      });
      resolve(filteredInspections);
    });
    return promise;
  }

  /**
   * Listen the changes applyed in the confidence slider and update the values
   */
  sliderChange() {
    const time = 1000;
    if (!this.isSearching) {
      this.isSearching = true;
      Observable.create((observer) => {
        this.slideChangeObserver = observer;
      }).pipe(debounceTime(time)) // wait 1s after the last event before emitting last event
        .pipe(distinctUntilChanged()) // only emit if value is different from previous value
        .subscribe(() => {
          this.loadHistoricalInspections();
        });
      this.slideChangeObserver.next();
    }
    this.firstLoad = false;
  }
  /**
   * Allow move between pages in the table
   * @param event
   */
  onPaginateChange(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.loadHistoricalInspections();
  }
  /**
   * Clean the filters. Is called for the button 'Limpiar filtros'
   */
  clearFilter() {
    this.initDate = undefined;
    this.endDate = undefined;
    this.agentCode = '';
    this.vehicleType = 'all';
    this.procedureType = 'all';
    this.origin = 'all';
    this.minValueSlide = 0;
    this.maxValueSlide = 100;
    this.pageIndex = 0;
    this.pageSize = 10;
    this.loadHistoricalInspections();
  }

  /**
   * Force refresh. Is called for the button 'Actualizar'
   */
  refresh() {
    this.loadHistoricalInspections();
  }

  export(): void {
    this.downloadLoading = true;
    const filter = {
      where: this.configureWhereFilter(),
      fields: {
        licencePlate: true,
        vehicleType: true,
        agentCode: true,
        date: true,
        province: true,
        procedureType: true,
        transactionId: true,
      },
      order: 'date DESC',
    };
    if (!filter.where) {
      delete filter.where;
    }
    this.historicalInspectionApi.count(filter.where).subscribe((size) => {
      if (size.count > 25000) {
        this.downloadLoading = false;
        this.toastr.error(
          `El tamaño del archivo que desea generar es de ${size.count} filas. El máximo de filas permitidas es de 25000.`,
          'Error',
          {
            positionClass: 'toast-bottom-right',
          });
      } else {
        this.historicalInspectionApi.find(filter).subscribe(
          (inspections) => {
            inspections.map((inspection: any) => {
              inspection.vehicleType = (inspection.vehicleType === 'car') ? 'Automóvil' :
                (inspection.vehicleType === 'motorbike') ? 'Moto' :
                  (inspection.vehicleType === 'bike') ? 'Bicicleta' :
                    (inspection.vehicleType === '0km') ? '0km' :
                      (inspection.vehicleType === 'truck') ? 'Camión' :
                        (inspection.vehicleType === 'bus') ? 'Colectivo' :
                          (inspection.vehicleType === 'trailer') ? 'Trailer' :
                            (inspection.vehicleType === 'semitrailer') ? 'Semitrailer' :
                              (inspection.vehicleType === 'caravan') ? 'Casilla Rodante' :
                                (inspection.vehicleType === 'tractor') ? 'Tractor' : 'Sin Definir';
              inspection.procedureType = (inspection.procedureType === 'incident') ? 'Siniestro' : 'Inspección previa';
              inspection.date = moment(inspection.date).format('DD/MM/YYYY HH:mm:ss');
            });
            const ws: WorkSheet = utils.json_to_sheet(inspections);
            let cellAddress = { c: 0, r: 0 };
            let cellRef = utils.encode_cell(cellAddress);
            ws[cellRef].v = 'Tipo de trámite';
            cellAddress = { c: 1, r: 0 };
            cellRef = utils.encode_cell(cellAddress);
            ws[cellRef].v = 'Código de productor';
            cellAddress = { c: 2, r: 0 };
            cellRef = utils.encode_cell(cellAddress);
            ws[cellRef].v = 'Patente';
            cellAddress = { c: 3, r: 0 };
            cellRef = utils.encode_cell(cellAddress);
            ws[cellRef].v = 'Tipo de vehículo';
            cellAddress = { c: 4, r: 0 };
            cellRef = utils.encode_cell(cellAddress);
            ws[cellRef].v = 'Id interno';
            cellAddress = { c: 5, r: 0 };
            cellRef = utils.encode_cell(cellAddress);
            ws[cellRef].v = 'Provincia';
            cellAddress = { c: 6, r: 0 };
            cellRef = utils.encode_cell(cellAddress);
            ws[cellRef].v = 'Fecha';

            /* generate workbook and add the worksheet */
            const wb: WorkBook = utils.book_new();
            utils.book_append_sheet(wb, ws, 'Sheet1');

            /* save to file */
            writeFile(wb, 'testXlsx.xlsx');
            this.downloadLoading = false;
          },
          () => {
            sweetalert2.fire({
              title: 'Ups! Algo salio mal',
              text: 'Ocurrió un error al obtener las inspecciones.',
              type: 'error',
              confirmButtonClass: 'btn btn-primary',
              confirmButtonText: 'Salir',
              buttonsStyling: false,
            });
          });
      }
    });
  }
  configureWhereFilter(): Object {
    const where = {
      and: [],
    };
    if (this.initDate) {
      where.and.push({ date: { gt: new Date(this.initDate) } });
    } else {
      localStorage.removeItem('initDate');
    }
    if (this.endDate) {
      where.and.push({ date: { lt: moment(this.endDate).endOf('day').toDate() } });
    }
    if (this.vehicleType !== 'all') {
      where.and.push({ vehicleType: this.vehicleType });
    }
    if (this.procedureType !== 'all') {
      if (this.procedureType === 'home') {
        where.and.push({ procedureType: 'incident' });
        where.and.push({ vehicleType: this.procedureType });
      } else if (this.procedureType === 'commerce') {
        where.and.push({ procedureType: 'incident' });
        where.and.push({ vehicleType: this.procedureType });
      } else {
        where.and.push({ procedureType: this.procedureType });
        where.and.push({ vehicleType: { neq: 'home' } });
        where.and.push({ vehicleType: { neq: 'commerce' } });
      }
    }
    if (this.origin !== 'all') {
      const value = this.origin !== 'link';
      where.and.push({ 'inspectionForm.sourceApp': { exists: value } });
    }
    if (this.zone !== 'all') {
      where.and.push({ province: this.zone });
    }
    if (this.aiFilter !== 'all') {
      const obj = this.spaObj.find((element) => element.name === this.aiFilter);
      where.and.push({
        [`pictures.analyzeResponse.${obj.name}`]:
        {
          [obj.cOperator]: obj.value,
        },
      });
    }

    if (this.minValueSlide > 0) {
      where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage':
        {
          gte: this.minValueSlide,
        },
      });
    }
    if (this.maxValueSlide < 100) {
      where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage':
        {
          lte: this.maxValueSlide,
        },
      });
    }
    if (this.licencePlate) {
      where.and.push({ licencePlate: this.licencePlate.toUpperCase() });
    }
    if (this.status === 'sent') {
      where.and.push({ isSuccessful: true });
    }
    if (this.status === 'pending') {
      where.and.push({ failedMsgList: { exists: false } });
      where.and.push({ isSuccessful: false });
    }
    if (this.status === 'failed') {
      where.and.push({ failedMsgList: { exists: true } });
      where.and.push({ isSuccessful: false });
    }
    if (this.agentCode) {
      where.and.push({ agentCode: this.agentCode });
    }
    if (this.transactionId) {
      where.and.push({ transactionId: this.transactionId });
    }
    if (where.and.length === 0) {
      return null;
    }
    return where;
  }

  inspectionLabel(inspection) {
    if (inspection.procedureType === 'incident' && inspection.vehicleType === 'home') {
      return 'Hogar';
    }
    if (inspection.procedureType === 'incident' && inspection.vehicleType === 'commerce') {
      return 'Comercio';
    }
    if (inspection.procedureType === 'incident') {
      return 'Vehículo';
    }
  }

  checkSmartAnalyzer(inspection) {
    const exists = inspection.pictures?.some((picture) => picture?.alreadyAnalyzed && picture?.analyzeResponse && this.smartAnalyzerService.getWarning(picture?.analyzeResponse));
    if (exists) {
      return true;
    }
  }

  sumGoodQuality(inspection) {
    const pictures = inspection.pictures.filter((picture) => picture?.alreadyAnalyzed && picture?.analyzeResponse && picture.analyzeResponse.goodQuality);
    let totalQuality = 0;
    pictures.forEach((element) => {
      totalQuality = totalQuality + element.analyzeResponse.goodQuality;
    });
    return pictures.length > 0 ? (totalQuality / pictures.length).toFixed(2) : '-';
  }

  showRadarChart(inspection) {
    this.radarChartData = inspection.pictures.map((picture, index) => {
      const colorIndex = index % this.colors.length;
      if (picture.alreadyAnalyzed && picture.analyzeResponse) {
        return {
          data: [
            1 - picture.analyzeResponse?.badFraming,
            1 - picture.analyzeResponse?.badLighting,
            picture.analyzeResponse?.containsACar,
            picture.analyzeResponse?.goodQuality,
            1 - picture.analyzeResponse?.pictureFromScreen,
          ],
          label: `${picture.description}`,
          backgroundColor: this.colors[colorIndex],
          borderColor: this.borderColors[colorIndex],
          borderWidth: 1,
        };
      }

    }).filter(dataSet => dataSet !== undefined);
    this.showChart = true;
  }

  async createSpaObj() {
    await this.smartAnalyzerService.getAnalysisThresholds();
    this.spaObj = [
      {
        name: 'badLighting',
        value: this.smartAnalyzerService.BAD_QUALITY_THRESHOLDS.minimumConfidenceBadLighting,
        cOperator: 'gte',
      },
      {
        name: 'containsACar',
        value: this.smartAnalyzerService.BAD_QUALITY_THRESHOLDS.minimumConfidenceContainsAcar,
        cOperator: 'lte',
      },
      {
        name: 'pictureFromScreen',
        value: this.smartAnalyzerService.BAD_QUALITY_THRESHOLDS.minimumConfidencePictureFromScreen,
        cOperator: 'gte',
      },
      {
        name: 'badFraming',
        value: this.smartAnalyzerService.BAD_QUALITY_THRESHOLDS.minimumConfidenceBadFraming,
        cOperator: 'gte',
      },
      {
        name: 'goodQuality',
        value: this.smartAnalyzerService.BAD_QUALITY_THRESHOLDS.minimumConfidenceGoodQuality,
        cOperator: 'gte',
      },

    ];
  }
}
