import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ClientApi, RoleApi } from '../../../shared/sdk/services/custom/index';
import { ToastrService } from 'ngx-toastr';
import sweetalert2 from 'sweetalert2';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {

  user;
  newUser: any;
  users;

  // table configuration
  public displayedColumns: string[] =
    ['name',  'email', 'role', 'actions'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  pageSize = 10;
  pageIndex = 0;
  public dataSource: MatTableDataSource<any>;

  @ViewChild('dialogRef', { static: true }) dialogRef: TemplateRef<any>;

  roles;
  isLoading: boolean;

  public constructor(
    private clientApi:  ClientApi,
    private roleApi: RoleApi,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.isLoading = true;
    this.roleApi.find().subscribe(
      (roles) => {
        this.roles = roles;
      });
    this.clientApi.searchClients().subscribe(
      (clients) => {
        this.users = clients;
        this.dataSource.data = clients;
        this.isLoading = false;
      });
  }

  createUser() {
    this.newUser = {};
    this.dialog.open(this.dialogRef, {});
  }

  saveUser() {
    if (this.newUser.id) { // User editing
      this.isLoading = true;
      this.clientApi.patchClient(this.newUser.id, this.newUser).subscribe(
        (editedUser) => {
          this.dataSource.data.forEach((user, index) => {
            if (user.id === this.newUser.id) {
              this.dataSource.data[index] = editedUser;
            }
          });
          this.isLoading = false;
          this.toastr.success(
            `El usuario ${editedUser.name} se ha editado correctamente`,
            'Usuario editado',
            {
              positionClass: 'toast-bottom-right',
            });
        },
        () => {
          this.isLoading = false;
          this.toastr.error(
            'Ha ocurrido un error al editar el usuario',
            'Error',
            {
              positionClass: 'toast-bottom-right',
            });
        },
      );
    } else { // new user
      this.isLoading = true;
      this.clientApi.createClient(this.newUser).subscribe(
        (createdClient) => {
          this.users.push(createdClient);
          this.dataSource.data = this.users;
          this.isLoading = false;
          this.toastr.success(
            `El usuario ${createdClient.name} se ha creado correctamente`,
            'Usuario creado',
            {
              positionClass: 'toast-bottom-right',
            });
        },
        () => {
          this.toastr.error(
            'Ha ocurrido un error al crear el usuario',
            'Error',
            {
              positionClass: 'toast-bottom-right',
            });
        });
    }
  }
  editUser(user) {
    this.newUser = user;
    this.newUser.roleId = user.roles[0].id;
    this.dialog.open(this.dialogRef, {});
  }
  deleteUser(user) {
    sweetalert2.fire({
      title: 'Eliminar usuario',
      text: `¿Está seguro que desea eliminar al usuario ${user.name}?`,
      type: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, Eliminar',
      customClass: {
        cancelButton: 'btn btn-ligth mx-1',
        confirmButton: 'btn btn-danger mx-1',
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        this.isLoading = true;
        this.clientApi.deleteClient(user.id).subscribe(
          () => {
            this.users = this.users.filter(userArray => userArray.id !== user.id);
            this.dataSource.data = this.users;
            this.isLoading = false;
            this.toastr.success(
              `El usuario ${user.name} ha sido eliminado`,
              'Usuario eliminado',
              {
                positionClass: 'toast-bottom-right',
              });
          },
          () => {
            this.isLoading = false;
            this.toastr.error(
              'Ha ocurrido un error al eliminar el usuario',
              'Error',
              {
                positionClass: 'toast-bottom-right',
              });
          },
        );
      }
    });
  }
  canEditUser(userToEdit) {
    return userToEdit.roles[0].hierarchy > this.user.roles[0].hierarchy;
  }
}
