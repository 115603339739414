import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { HistoricalInspectionApi, ProvinceApi } from '../../shared/sdk/services/custom';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import sweetalert2 from 'sweetalert2';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import * as moment from 'moment';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent implements OnInit {

  colors = {
    motorbike: {
      backgroundColor: '#78BE20',
      borderColor: '#78BE20',
      hoverBackgroundColor: '#78BE20',
      hoverBorderColor: '#78BE20',
    },
    bike: {
      backgroundColor: '#007749',
      borderColor: '#007749',
      hoverBackgroundColor: '#007749',
      hoverBorderColor: '#007749',
    },
    _0km: {
      backgroundColor: '#E56A54',
      borderColor: '#E56A54',
      hoverBackgroundColor: '#E56A54',
      hoverBorderColor: '#E56A54',
    },
    car: {
      backgroundColor: '#F0B323',
      borderColor: '#F0B323',
      hoverBackgroundColor: '#F0B323',
      hoverBorderColor: '#F0B323',
    },
    bus: {
      backgroundColor: '#F9E1A7',
      borderColor: '#F9E1A7',
      hoverBackgroundColor: '#F9E1A7',
      hoverBorderColor: '#F9E1A7',
    },
    truck: {
      backgroundColor: '#005333',
      borderColor: '#005333',
      hoverBackgroundColor: '#005333',
      hoverBorderColor: '#005333',
    },
    tractor: {
      backgroundColor: '#B3D6C8',
      borderColor: '#B3D6C8',
      hoverBackgroundColor: '#B3D6C8',
      hoverBorderColor: '#B3D6C8',
    },
    trailer: {
      backgroundColor: '#A1D263',
      borderColor: '#A1D263',
      hoverBackgroundColor: '#A1D263',
      hoverBorderColor: '#A1D263',
    },
    semitrailer: {
      backgroundColor: '#A04A3B',
      borderColor: '#A04A3B',
      hoverBackgroundColor: '#A04A3B',
      hoverBorderColor: '#A04A3B',
    },
    caravan: {
      backgroundColor: '#ED9787',
      borderColor: '#ED9787',
      hoverBackgroundColor: '#ED9787',
      hoverBorderColor: '#ED9787',
    },
  };

  inspections;
  charType = 'yearly';
  minValueSlide = 0;
  maxValueSlide = 100;

  isSearching: boolean;

  slideChangeObserver;
  slidersRefresh: EventEmitter<void> = new EventEmitter<void>();

  provinces = [];
  zone;
  months =
    ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
      'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  @ViewChild('termSearchInput', { static: true }) termSearchInput: ElementRef;
  agentCod;
  procedureType = 'all';

  // table configuration
  public displayedColumns: string[] =
    ['provinceName',  'number', 'percentage'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 15, 24];
  elementsNumber: number;
  public dataSource: MatTableDataSource<any>;
  // Geographic filters
  minValueSlideGeo = 0;
  maxValueSlideGeo = 100;
  slideChangeObserverGeo;
  slidersRefreshGeo: EventEmitter<void> = new EventEmitter<void>();
  procedureTypeGeo = 'all';
  isLoadingListGeo;
  initDateGeo = moment().startOf('month').toDate();
  endDateGeo;
  vehicleTypeGeo;
  isSearchingGeo;
  inspectionsGeo;
  @ViewChild('statisticsPage', { static: true }) statisticsPage;

  // Pie chart configuration

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public horizontalBarChartLabels: Label[] = [];
  public horizontalBarChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'horizontalBar';
  public pieChartLegend = false;
  public pieChartPlugins = [];
  horizontalBarColors = [];
  params;

  constructor(private historicalInspectionApi: HistoricalInspectionApi,
    private provinceApi: ProvinceApi,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.isLoadingList = true;
    this.isLoadingListGeo = true;
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
    this.getFiltersByURL();
    this.filterGeographicInfo();
    this.filterCustom();
    fromEvent(this.termSearchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      // if character length greater then 2
      ,
      filter(res => (res.length > 1 || res.length === 0))
      // Time in milliseconds between key events
      ,
      debounceTime(1000)
      // If previous query is diffent from current
      ,
      distinctUntilChanged(),
      // subscription for response
    ).subscribe(() => {
      this.isSearching = true;
      this.filterCustom();
    });
    this.loadProvinces();
  }
  getFiltersByURL() {
    if (this.params['initDate']) {
      this.initDate = new Date(this.params['initDate']);
    }
    if (this.params['endDate']) {
      this.endDate = new Date(this.params['endDate']);
    }
    if (this.params['agentCod']) {
      this.agentCod = this.params['agentCod'];
    }
    if (this.params['vehicleType']) {
      this.vehicleType = this.params['vehicleType'];
    }
    if (this.params['zone']) {
      this.zone = this.params['zone'];
    }
    if (this.params['procedureType']) {
      this.procedureType = this.params['procedureType'];
    }
    if (this.params['minValueSlide']) {
      this.minValueSlide = Number(this.params['minValueSlide']);
    }
    if (this.params['maxValueSlide']) {
      this.maxValueSlide = Number(this.params['maxValueSlide']);
    }
    if (this.params['initDateGeo']) {
      this.initDateGeo = new Date(this.params['initDateGeo']);
    }
    if (this.params['endDateGeo']) {
      this.endDateGeo = new Date(this.params['endDateGeo']);
    }
    if (this.params['vehicleTypeGeo']) {
      this.vehicleTypeGeo = this.params['vehicleTypeGeo'];
    }
    if (this.params['procedureTypeGeo']) {
      this.procedureTypeGeo = this.params['procedureTypeGeo'];
    }
    if (this.params['minValueSlideGeo']) {
      this.minValueSlideGeo = Number(this.params['minValueSlideGeo']);
    }
    if (this.params['maxValueSlideGeo']) {
      this.maxValueSlideGeo = Number(this.params['maxValueSlideGeo']);
    }
  }

  loadChart() {
    this.isLoadingList = true;
    if (this.charType === 'yearly') {
      this.getYearlyInspections();
    } else if (this.charType === 'monthly') {
      this.getMonthlyInspections();
    } else if (this.charType === 'weekly') {
      this.getWeeklyInspections();
    }
  }

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    // Can't just just `stacked: true` like the docs say
    scales: {
      yAxes: [{
        stacked: true,
      }],
      xAxes: [{
        stacked: true,
      }],
    },
    animation: {
      duration: 750,
    },
  };
  public barChartLabels;
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData;

  initDate: Date = moment().startOf('month').toDate();
  endDate: Date;
  vehicleType = 'all';

  isLoadingList: boolean;

  filterCustom() {
    this.charType = 'none';
    this.isLoadingList = true;
    const filter = {
      where: {
        and: [
        ],
      },
      fields: {
        date: true,
        vehicleType: true,
      },
    };
    if (this.initDate) {
      filter.where.and.push({ date: { gt: new Date(this.initDate) } });
    }
    if (this.endDate) {
      const endDate =  moment(this.endDate).endOf('day').toDate();
      filter.where.and.push({ date: { lt: endDate } });
    }
    if (this.vehicleType !== 'all') {
      filter.where.and.push({ vehicleType: this.vehicleType });
    }
    if (this.procedureType !== 'all') {
      filter.where.and.push({ procedureType: this.procedureType });
    }
    if (this.zone !== 'all') {
      filter.where.and.push({ province: this.zone });
    }
    if (this.minValueSlide > 0) {
      filter.where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage' :
        {
          gte: this.minValueSlide,
        },
      });
    }
    if (this.maxValueSlide < 100) {
      filter.where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage' :
        {
          lte: this.maxValueSlide,
        },
      });
    }
    if (this.agentCod) {
      const condition = { agentCode: this.agentCod };
      filter.where.and.push(condition);
    }
    if (filter.where.and.length === 0) {
      delete filter.where;
    }

    this.historicalInspectionApi.find(filter).subscribe(
      (inspections) => {
        this.isLoadingList = false;
        this.isSearching = false;
        this.inspections = inspections;
        this.automaticProcess();
        this.addFiltersToURL();
      },
      () => {
        this.toastr.error(
          'No podemos obtener los datos en este momento.' +
          ' Intenta nuevamente más tarde.',
          'Error', {
            positionClass: 'toast-bottom-right',
          });

      },
    );
  }
  addFiltersToURL() {
    this.router.navigate(
      [], {
        relativeTo: this.route,
        queryParams: {
          initDate: this.initDate,
          endDate: this.endDate,
          agentCod: this.agentCod,
          vehicleType: this.vehicleType,
          zone: this.zone,
          procedureType: this.procedureType,
          minValueSlide: this.minValueSlide,
          maxValueSlide: this.maxValueSlide,
          initDateGeo: this.initDateGeo,
          endDateGeo: this.endDateGeo,
          vehicleTypeGeo: this.vehicleTypeGeo,
          procedureTypeGeo: this.procedureTypeGeo,
          minValueSlideGeo: this.minValueSlideGeo,
          maxValueSlideGeo: this.maxValueSlideGeo,
        },
        queryParamsHandling: 'merge',
      });
  }

  automaticProcess() {
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    let initDate = this.initDate;
    let endDate = this.endDate;
    if (!initDate) {
      const firstDate = this.getFirstDate();
      initDate = (firstDate) ? firstDate : today;
    }
    if (!endDate) {
      endDate = today;
    }

    const diffTime = Math.abs(initDate.getTime() - endDate.getTime());
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    const diffWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));

    if (diffDays < 30) {
      this.processInDays(initDate, endDate);
    } else if (diffWeeks < 30) {
      this.proccessInWeeks(initDate, endDate);
    } else {
      this.proccessInMonths(initDate, endDate);
    }
  }

  processInDays(initDate, endDate) {
    this.barChartLabels = [];
    const daysOfMonth = this.daysInMonth(initDate.getMonth() + 1, initDate.getFullYear());
    const data = {
      carData : [],
      motorbikeData : [],
      '0kmData' : [],
      bikeData : [],
      truckData : [],
      busData : [],
      tractorData : [],
      trailerData : [],
      semitrailerData : [],
      caravanData : [],
    };

    const diffTime = Math.abs(initDate.getTime() - endDate.getTime());
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    let colSpace;
    if (diffDays < 15) {
      colSpace = 1;
    } else {
      colSpace = 4;
    }

    const initialDay = initDate.getDate();

    for (let i = 0; i <= diffDays; i = i + 1) {
      data.carData[i] = 0;
      data.motorbikeData[i] = 0;
      data['0kmData'][i] = 0;
      data.bikeData[i] = 0;
      data.truckData[i] = 0;
      data.busData[i] = 0;
      data.tractorData[i] = 0;
      data.trailerData[i] = 0;
      data.semitrailerData[i] = 0;
      data.caravanData[i] = 0;
      if (i % colSpace === 0) {
        if ((i + initialDay) <= daysOfMonth) {
          this.barChartLabels[i] = `${initialDay + i }/${initDate.getMonth() + 1}`;
        } else {
          this.barChartLabels[i] = `${i + initialDay - daysOfMonth }/${endDate.getMonth() + 1}`;
        }
      } else {
        this.barChartLabels[i] = '';
      }
    }
    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      if (inspection.vehicleType) {
        if (initialDay <= date.getDate()) {
          data[`${inspection.vehicleType}Data`][date.getDate() - initialDay] =
            data[`${inspection.vehicleType}Data`][date.getDate() - initialDay] + 1;
        } else {
          data[`${inspection.vehicleType}Data`][daysOfMonth - initialDay + date.getDate()] =
            data[`${inspection.vehicleType}Data`][daysOfMonth - initialDay + date.getDate()] + 1;
        }
      }

    });
    this.configureChart(
      data.carData,
      data.motorbikeData,
      data['0kmData'],
      data.bikeData,
      data.busData,
      data.trailerData,
      data.semitrailerData,
      data.tractorData,
      data.caravanData,
      data.truckData);
  }

  proccessInWeeks(initDate, endDate) {
    this.barChartLabels = [];
    const weeksOfYear = this.getWeekNumberInYear(initDate.getFullYear());
    const data = {
      carData : [],
      motorbikeData : [],
      '0kmData' : [],
      bikeData : [],
      truckData : [],
      busData : [],
      tractorData : [],
      trailerData : [],
      semitrailerData : [],
      caravanData : [],
    };

    const diffTime = Math.abs(initDate.getTime() - endDate.getTime());
    const diffWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));

    let colSpace;
    if (diffWeeks < 12) {
      colSpace = 1;
    } else {
      colSpace = 4;
    }
    const initialWeek = this.getWeekNumber(initDate);
    let mondayDay;
    let sundayDay;
    for (let i = 0; i < diffWeeks; i = i + 1) {
      data.carData[i] = 0;
      data.motorbikeData[i] = 0;
      data['0kmData'][i] = 0;
      data.bikeData[i] = 0;
      data.truckData[i] = 0;
      data.busData[i] = 0;
      data.tractorData[i] = 0;
      data.trailerData[i] = 0;
      data.semitrailerData[i] = 0;
      data.caravanData[i] = 0;
      if (i % colSpace === 0) {
        if ((i + initialWeek) <= weeksOfYear) {
          mondayDay = this.getMondayOfWeek(initialWeek + i, initDate.getFullYear());
          sundayDay = this.getSundayOfWeek(initialWeek + i, initDate.getFullYear());
          this.barChartLabels[i] = [
            `Semana ${initialWeek + i }`,
            this.dateTextFormat(mondayDay, sundayDay),
            (mondayDay.getFullYear() !== sundayDay.getFullYear()) ?
              `${mondayDay.getFullYear()}-${sundayDay.getFullYear()}` : mondayDay.getFullYear(),
          ];
        } else {
          mondayDay = this.getMondayOfWeek(i - (weeksOfYear - initialWeek), endDate.getFullYear());
          sundayDay = this.getSundayOfWeek(i - (weeksOfYear - initialWeek), endDate.getFullYear());
          this.barChartLabels[i] = [
            `Semana ${i + initialWeek - weeksOfYear}`,
            this.dateTextFormat(mondayDay, sundayDay),
            (mondayDay.getFullYear() !== sundayDay.getFullYear()) ?
              `${mondayDay.getFullYear()}-${sundayDay.getFullYear()}` : mondayDay.getFullYear(),
          ];
        }
      } else {
        this.barChartLabels[i] = '';
      }
    }

    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      if (initialWeek <= this.getWeekNumber(date)) {
        data[`${inspection.vehicleType}Data`][this.getWeekNumber(date) - initialWeek] += 1;
      } else {
        data[`${inspection.vehicleType}Data`][weeksOfYear - initialWeek + this.getWeekNumber(date)] += 1;
      }
    });
    this.configureChart(
      data.carData,
      data.motorbikeData,
      data['0kmData'],
      data.bikeData,
      data.busData,
      data.trailerData,
      data.semitrailerData,
      data.tractorData,
      data.caravanData,
      data.truckData);
  }
  proccessInMonths(initDate, endDate) {
    this.barChartLabels = [];
    const carData = [];
    const motorBikeData = [];
    const zeroKmData = [];
    const bikeData = [];
    const truckData = [];
    const busData = [];
    const tractorData = [];
    const trailerData = [];
    const semitrailerData = [];
    const caravanData = [];
    const diffTime = Math.abs(initDate.getTime() - endDate.getTime());
    const diffMonth = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
    let colSpace;
    if (diffMonth < 15) {
      colSpace = 1;
    } else {
      colSpace = 4;
    }
    const initialMonth = initDate.getMonth();
    const year = initDate.getFullYear();
    const fullLabels = [];
    for (let i = 0; i < diffMonth; i = i + 1) {
      carData[i] = 0;
      motorBikeData[i] = 0;
      zeroKmData[i] = 0;
      bikeData[i] = 0;
      truckData[i] = 0;
      busData[i] = 0;
      tractorData[i] = 0;
      trailerData[i] = 0;
      semitrailerData[i] = 0;
      caravanData[i] = 0;
      fullLabels[i] =
        [(initialMonth + i) % 12, year + (Math.ceil((initialMonth + i + 1) / 12) - 1)];
      if (i % colSpace === 0) {
        this.barChartLabels[i] =
        [this.months[(initialMonth + i) % 12],
          year + (Math.ceil((initialMonth + i + 1) / 12) - 1)];
      } else {
        this.barChartLabels[i] = '';
      }
    }
    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      const i = this.getIndexByMonthYear(fullLabels, date);
      switch (inspection.vehicleType) {
        case 'car': {
          carData[i] = carData[i] + 1;
          break;
        }
        case 'motorbike': {
          motorBikeData[i] =
              motorBikeData[i] + 1;
          break;
        }
        case '0km': {
          zeroKmData[i] =
              zeroKmData[i] + 1;
          break;
        }
        case 'bike': {
          bikeData[i] =
              bikeData[i] + 1;
          break;
        }
        case 'bus': {
          busData[i] =
              busData[i] + 1;
          break;
        }
        case 'truck': {
          // eslint-disable-next-line no-self-assign
          truckData[i] = truckData[i];
          break;
        }
        case 'tractor': {
          tractorData[i] =
              tractorData[i] + 1;
          break;
        }
        case 'trailer': {
          trailerData[i] =
              trailerData[i] + 1;
          break;
        }
        case 'semitrailer': {
          semitrailerData[i] =
              semitrailerData[i] + 1;
          break;
        }
        case 'caravan': {
          caravanData[i] =
              caravanData[i] + 1;
          break;
        }
      }
    });
    this.configureChart(
      carData, motorBikeData, zeroKmData, bikeData,
      busData, trailerData, semitrailerData, tractorData,
      caravanData, truckData);
  }
  getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }
  getWeekNumberInYear(year) {
    const lastDayOfYear = new Date(year, 11, 31, 0, 0, 0);
    return this.getWeekNumber(lastDayOfYear) - 1;
  }
  getFirstDate() {
    if (this.inspections.length > 0) {
      let moreOld = this.inspections[0];
      this.inspections.forEach((insp) => {
        if (insp.date < moreOld) {
          moreOld = insp.date;
        }
      });
      let date = new Date(moreOld);
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
      return date;
    }
    return null;
  }
  getYearlyInspections() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), 0, 1);
    const lastDay = new Date(date.getFullYear(), 12, 0);
    const endDate = moment(lastDay).endOf('day').toDate();
    const filter = {
      where: {
        and: [
          {
            date: {
              gte: firstDay,
            },
          },
          {
            date: {
              lte: endDate,
            },
          },
        ],
      },
      fields: {
        date: true,
        vehicleType: true,
        province: true,
      },
    };
    this.historicalInspectionApi.find(filter).subscribe(
      (inspections) => {
        this.isLoadingList = false;
        this.isSearching = false;
        this.inspections = inspections;
        this.proccessYearlyInspection();
      },
      () => {
        this.toastr.error(
          'No podemos obtener los datos en este momento. Intenta nuevamente más tarde.',
          'Error', {
            positionClass: 'toast-bottom-right',
          });
      },
    );
  }
  getMonthlyInspections() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const filter = {
      where: {
        and: [
          {
            date: {
              gte: firstDay,
            },
          },
          {
            date: {
              lte: lastDay,
            },
          },
        ],
      },
      fields: {
        date: true,
        vehicleType: true,
      },
    };
    this.historicalInspectionApi.find(filter).subscribe(
      (inspections) => {
        this.isLoadingList = false;
        this.isSearching = false;
        this.inspections = inspections;
        this.proccessMonthlyInspection();
      },
      () => {
        this.toastr.error(
          'No podemos obtener los datos en este momento. Intenta nuevamente más tarde.',
          'Error', {
            positionClass: 'toast-bottom-right',
          });
      },
    );
  }
  getWeeklyInspections() {
    const firstDay = this.getMonday(new Date());
    const lastDay = this.addDays(firstDay, 6);
    const endDate = moment(lastDay).endOf('day').toDate();
    const filter = {
      where: {
        and: [
          {
            date: {
              gte: firstDay,
            },
          },
          {
            date: {
              lte: endDate,
            },
          },
        ],
      },
      fields: {
        date: true,
        vehicleType: true,
      },
    };
    this.historicalInspectionApi.find(filter).subscribe(
      (inspections) => {
        this.isLoadingList = false;
        this.isSearching = false;
        this.inspections = inspections;
        this.proccessWeeklyInspection(firstDay);
      },
      () => {
        this.toastr.error(
          'No podemos obtener los datos en este momento. Intenta nuevamente más tarde.',
          'Error', {
            positionClass: 'toast-bottom-right',
          });
      },
    );
  }

  proccessYearlyInspection() {
    this.barChartLabels =
    ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
      'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const carData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const motorBikeData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const zeroKmData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const bikeData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const busData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const truckData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const tractorData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const trailerData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const semitrailerData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const caravanData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      switch (inspection.vehicleType) {
        case 'car': {
          carData[date.getMonth()] = carData[date.getMonth()] + 1;
          break;
        }
        case 'motorbike': {
          motorBikeData[date.getMonth()] = motorBikeData[date.getMonth()] + 1;
          break;
        }
        case '0km': {
          zeroKmData[date.getMonth()] = zeroKmData[date.getMonth()] + 1;
          break;
        }
        case 'bike': {
          bikeData[date.getMonth()] = bikeData[date.getMonth()] + 1;
          break;
        }
        case 'bus': {
          busData[date.getMonth()] = busData[date.getMonth()] + 1;
          break;
        }
        case 'truck': {
          truckData[date.getMonth()] = truckData[date.getMonth()] + 1;
          break;
        }
        case 'tractor': {
          tractorData[date.getMonth()] = tractorData[date.getMonth()] + 1;
          break;
        }
        case 'trailer': {
          trailerData[date.getMonth()] = trailerData[date.getMonth()] + 1;
          break;
        }
        case 'semitrailer': {
          semitrailerData[date.getMonth()] = semitrailerData[date.getMonth()] + 1;
          break;
        }
        case 'caravan': {
          caravanData[date.getMonth()] = caravanData[date.getMonth()] + 1;
          break;
        }
      }
    });
    this.configureChart(
      carData, motorBikeData, zeroKmData, bikeData,
      busData, trailerData, semitrailerData, tractorData,
      caravanData, truckData);
  }
  proccessMonthlyInspection() {
    this.barChartLabels = [];
    const today = new Date();
    const daysOfMonth = this.daysInMonth(today.getMonth(), today.getFullYear());
    const carData = [];
    const motorBikeData = [];
    const zeroKmData = [];
    const bikeData = [];
    const busData = [];
    const truckData = [];
    const trailerData = [];
    const semitrailerData = [];
    const tractorData = [];
    const caravanData = [];
    for (let i = 0; i < daysOfMonth; i = i + 1) {
      carData[i] = 0;
      motorBikeData[i] = 0;
      zeroKmData[i] = 0;
      bikeData[i] = 0;
      busData[i] = 0;
      truckData[i] = 0;
      trailerData[i] = 0;
      semitrailerData[i] = 0;
      tractorData[i] = 0;
      caravanData[i] = 0;
      if (i % 4 === 0) {
        this.barChartLabels[i] = `${i + 1}/${today.getMonth() + 1}`;
      } else {
        this.barChartLabels[i] = '';
      }
    }
    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      switch (inspection.vehicleType) {
        case 'car': {
          carData[date.getDate()] = carData[date.getDate()] + 1;
          break;
        }
        case 'motorbike': {
          motorBikeData[date.getDate()] = motorBikeData[date.getDate()] + 1;
          break;
        }
        case '0km': {
          zeroKmData[date.getDate()] = zeroKmData[date.getDate()] + 1;
          break;
        }
        case 'bike': {
          bikeData[date.getDate()] = bikeData[date.getDate()] + 1;
          break;
        }
        case 'bus': {
          busData[date.getDate()] = busData[date.getDate()] + 1;
          break;
        }
        case 'truck': {
          truckData[date.getDate()] = truckData[date.getDate()] + 1;
          break;
        }
        case 'trailer': {
          trailerData[date.getDate()] = trailerData[date.getDate()] + 1;
          break;
        }
        case 'semitrailer': {
          semitrailerData[date.getDate()] = semitrailerData[date.getDate()] + 1;
          break;
        }
        case 'tractor': {
          tractorData[date.getDate()] = tractorData[date.getDate()] + 1;
          break;
        }
        case 'caravan': {
          caravanData[date.getDate()] = caravanData[date.getDate()] + 1;
          break;
        }
      }
    });
    this.configureChart(
      carData, motorBikeData, zeroKmData, bikeData,
      busData, trailerData, semitrailerData, tractorData,
      caravanData, truckData);
  }
  proccessWeeklyInspection(firstDay) {
    this.barChartLabels = [];
    const today = new Date();
    const carData = [];
    const motorBikeData = [];
    const zeroKmData = [];
    const bikeData = [];
    const busData = [];
    const trailerData = [];
    const semitrailerData = [];
    const tractorData = [];
    const caravanData = [];
    const truckData = [];
    for (let i = firstDay.getDate(); i < firstDay.getDate() + 7; i = i + 1) {
      const index = i - firstDay.getDate();
      carData[index] = 0;
      motorBikeData[index] = 0;
      zeroKmData[index] = 0;
      bikeData[index] = 0;
      bikeData[index] = 0;
      busData[index] = 0;
      trailerData[index] = 0;
      semitrailerData[index] = 0;
      tractorData[index] = 0;
      caravanData[index] = 0;
      truckData[index] = 0;
      this.barChartLabels[index] = `${i}/${today.getMonth() + 1}`;
    }
    this.inspections.forEach((inspection) => {
      const date = new Date(inspection.date);
      const index = date.getDate() - firstDay.getDate();
      switch (inspection.vehicleType) {
        case 'car': {
          carData[index] = carData[index] + 1;
          break;
        }
        case 'motorbike': {
          motorBikeData[index] = motorBikeData[index] + 1;
          break;
        }
        case '0km': {
          zeroKmData[index] = zeroKmData[index] + 1;
          break;
        }
        case 'bike': {
          bikeData[index] = bikeData[index] + 1;
          break;
        }
        case 'bus': {
          busData[index] = busData[index] + 1;
          break;
        }
        case 'trailer': {
          trailerData[index] = trailerData[index] + 1;
          break;
        }
        case 'semitrailer': {
          semitrailerData[index] = semitrailerData[index] + 1;
          break;
        }
        case 'tractor': {
          tractorData[index] = tractorData[index] + 1;
          break;
        }
        case 'caravan': {
          caravanData[index] = caravanData[index] + 1;
          break;
        }
        case 'truck': {
          truckData[index] = truckData[index] + 1;
          break;
        }
      }
    });
    this.configureChart(
      carData, motorBikeData, zeroKmData, bikeData,
      busData, trailerData, semitrailerData, tractorData,
      caravanData, truckData);
  }
  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
  getMondayOfWeek(week, year) {
    const firstDayOfYear = new Date(year, 0, 1);
    const days = 7 * (week - 1);
    const calcDate = this.addDays(firstDayOfYear, days);
    return this.getMonday(calcDate);
  }
  getSundayOfWeek(week, year) {
    const firstDayOfYear = new Date(year, 0, 1);
    const days = 7 * (week - 1);
    const calcDate = this.addDays(firstDayOfYear, days);
    return this.getSunday(calcDate);
  }
  getMonday(aDay) {
    const d = new Date(aDay);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  getSunday(aDay) {
    const d = new Date(aDay);
    const day = d.getDay();
    const diff = d.getDate() + (7 - day);
    return new Date(d.setDate(diff));
  }
  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  configureChart(carData, motorBikeData, zeroKmData, bikeData,
    busData, trailerData, semitrailerData, tractorData,
    caravanData, truckData) {
    this.barChartData = [
      { data: carData, label: 'Autos',  fill: true,
        backgroundColor: this.colors.car.backgroundColor,
        hoverBackgroundColor: this.colors.car.hoverBackgroundColor,
        borderColor: this.colors.car.borderColor,
        hoverBorderColor: this.colors.car.hoverBorderColor },
      { data: motorBikeData, label: 'Motos',  fill: true,
        backgroundColor: this.colors.motorbike.backgroundColor,
        hoverBackgroundColor: this.colors.motorbike.hoverBackgroundColor,
        borderColor: this.colors.motorbike.borderColor,
        hoverBorderColor: this.colors.motorbike.hoverBorderColor },
      { data: zeroKmData, label: 'Autos 0km',  fill: true,
        backgroundColor: this.colors._0km.backgroundColor,
        hoverBackgroundColor: this.colors._0km.hoverBackgroundColor,
        borderColor: this.colors._0km.borderColor,
        hoverBorderColor: this.colors._0km.hoverBorderColor },
      { data: bikeData, label: 'Bicicletas',  fill: true,
        backgroundColor: this.colors.bike.backgroundColor,
        hoverBackgroundColor: this.colors.bike.hoverBackgroundColor,
        borderColor: this.colors.bike.borderColor,
        hoverBorderColor: this.colors.bike.hoverBorderColor },
      { data: busData, label: 'Colectivos',  fill: true,
        backgroundColor: this.colors.bus.backgroundColor,
        hoverBackgroundColor: this.colors.bus.hoverBackgroundColor,
        borderColor: this.colors.bus.borderColor,
        hoverBorderColor: this.colors.bus.hoverBorderColor },
      { data: truckData, label: 'Camiones',  fill: true,
        backgroundColor: this.colors.truck.backgroundColor,
        hoverBackgroundColor: this.colors.truck.hoverBackgroundColor,
        borderColor: this.colors.truck.borderColor,
        hoverBorderColor: this.colors.truck.hoverBorderColor },
      { data: tractorData, label: 'Tractores',  fill: true,
        backgroundColor: this.colors.tractor.backgroundColor,
        hoverBackgroundColor: this.colors.tractor.hoverBackgroundColor,
        borderColor: this.colors.tractor.borderColor,
        hoverBorderColor: this.colors.tractor.hoverBorderColor },
      { data: caravanData, label: 'Casillas Rodantes',  fill: true,
        backgroundColor: this.colors.caravan.backgroundColor,
        hoverBackgroundColor: this.colors.caravan.hoverBackgroundColor,
        borderColor: this.colors.caravan.borderColor,
        hoverBorderColor: this.colors.caravan.hoverBorderColor },
      { data: trailerData, label: 'Trailers',  fill: true,
        backgroundColor: this.colors.trailer.backgroundColor,
        hoverBackgroundColor: this.colors.trailer.hoverBackgroundColor,
        borderColor: this.colors.trailer.borderColor,
        hoverBorderColor: this.colors.trailer.hoverBorderColor },
      { data: semitrailerData, label: 'Semitrailers',  fill: true,
        backgroundColor: this.colors.semitrailer.backgroundColor,
        hoverBackgroundColor: this.colors.semitrailer.hoverBackgroundColor,
        borderColor: this.colors.semitrailer.borderColor,
        hoverBorderColor: this.colors.semitrailer.hoverBorderColor },
    ];
  }
  sliderChange() {
    if (!this.isSearching) {
      this.isSearching = true;
      Observable.create((observer) => {
        this.slideChangeObserver = observer;
      }).pipe(debounceTime(1000)) // wait 1s after the last event before emitting last event
        .pipe(distinctUntilChanged()) // only emit if value is different from previous value
        .subscribe(() => {
          this.filterCustom();
        });
      this.slideChangeObserver.next();
    }
  }
  dateTextFormat(initDate, endDate) {
    const monthNames = ['Ene', 'Feb', 'Mar',
      'Abr', 'May', 'Jun',
      'Jul', 'Ago', 'Sep',
      'Oct', 'Nov', 'Dic'];
    const initDay = initDate.getDate();
    const initMonthIndex = initDate.getMonth();
    const endDay = endDate.getDate();
    const endMonthIndex = endDate.getMonth();
    let text = '';
    if (initMonthIndex === endMonthIndex) {
      text = `${initDay} -  ${endDay} de  ${monthNames[initMonthIndex]}`;
    } else  {
      text =
        `${initDay} de ${monthNames[initMonthIndex]} - ${endDay} de ${monthNames[endMonthIndex]}`;
    }
    return text;
  }
  loadProvinces() {
    this.provinces = [];
    this.provinceApi.find({ fields:{ name:true } }).subscribe((provinces) => {
      this.provinces = provinces;
      // To remove
    },                                                        () => {
      sweetalert2.fire({
        title: 'Ups! Algo salio mal',
        text: 'Ocurrió un error al obtener las provincias.',
        type: 'error',
        confirmButtonClass: 'btn btn-primary',
        confirmButtonText: 'Salir',
        buttonsStyling: false,
      });
    });
  }
  clearFilter() {
    this.initDate = undefined;
    this.endDate = undefined;
    this.agentCod = '';
    this.vehicleType = 'all';
    this.procedureType = 'all';
    this.minValueSlide = 0;
    this.maxValueSlide = 100;
    this.filterCustom();
  }
  getIndexByMonthYear(fullLabels, date): number {
    let retIndex = null;
    fullLabels.forEach((label, index) => {
      if (date.getMonth() === label[0] && date.getFullYear() === label[1]) {
        retIndex = index;
      }
    });
    return retIndex;
  }

  processGeographicData() {
    let geographicData = [];
    const all = this.inspectionsGeo.length;
    this.provinces.forEach((province) => {
      const number = this.inspectionsGeo.filter((inspection) => {
        return inspection.province === province.name;
      }).length;
      geographicData.push({
        number,
        name: province.name,
        percentage: (all !== 0) ? (number * 100 / all).toFixed(2) : 0,
      });
    });
    geographicData = geographicData.sort((a, b) => {
      if (a.number < b.number) {
        return 1;
      }
      if (a.number > b.number) {
        return -1;
      }
      return 0;
    });
    this.dataSource.data = geographicData;
    this.configureHorizontalBarChart(geographicData);
  }
  filterGeographicInfo() {
    this.isLoadingListGeo = true;
    const filter = {
      where: {
        and: [
        ],
      },
      fields: {
        date: true,
        vehicleType: true,
        province: true,
      },
    };
    if (this.initDateGeo) {
      filter.where.and.push({ date: { gt: new Date(this.initDateGeo) } });
    }
    if (this.endDateGeo) {
      const endDate = moment(this.endDateGeo).endOf('day').toDate();
      filter.where.and.push({ date: { lt: new Date(endDate) } });
    }
    if (this.vehicleTypeGeo !== 'all') {
      filter.where.and.push({ vehicleType: this.vehicleTypeGeo });
    }
    if (this.procedureTypeGeo !== 'all') {
      filter.where.and.push({ procedureType: this.procedureTypeGeo });
    }
    if (this.minValueSlideGeo > 0) {
      filter.where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage' :
        {
          gte: this.minValueSlideGeo,
        },
      });
    }
    if (this.maxValueSlideGeo < 100) {
      filter.where.and.push({
        'imageRecognitionData.ocrRecognizedPercentage' :
        {
          lte: this.maxValueSlideGeo,
        },
      });
    }
    if (filter.where.and.length === 0) {
      delete filter.where;
    }
    this.historicalInspectionApi.find(filter).subscribe(
      (inspections) => {
        this.isLoadingListGeo = false;
        this.isSearchingGeo = false;
        this.inspectionsGeo = inspections;
        this.processGeographicData();
        this.addFiltersToURL();
      },
      () => {
        this.toastr.error(
          'No podemos obtener los datos en este momento.' +
          ' Intenta nuevamente más tarde.',
          'Error', {
            positionClass: 'toast-bottom-right',
          });

      },
    );
  }
  sliderChangeGeo() {
    if (!this.isSearchingGeo) {
      this.isSearchingGeo = true;
      Observable.create((observer) => {
        this.slideChangeObserverGeo = observer;
      }).pipe(debounceTime(1000)) // wait 1s after the last event before emitting last event
        .pipe(distinctUntilChanged()) // only emit if value is different from previous value
        .subscribe(() => {
          this.filterGeographicInfo();
        });
      this.slideChangeObserverGeo.next();
    }
  }
  clearFilterGeo() {
    this.initDateGeo = undefined;
    this.endDateGeo = undefined;
    this.vehicleTypeGeo = 'all';
    this.procedureTypeGeo = 'all';
    this.minValueSlideGeo = 0;
    this.maxValueSlideGeo = 100;
    this.filterGeographicInfo();
  }
  configureHorizontalBarChart(geographicData) {
    this.horizontalBarChartLabels = [];
    this.horizontalBarChartData = [];
    let otherAmount = 0;
    geographicData.forEach((provinceData, index) => {
      if (index < 7) {
        this.horizontalBarChartLabels.push(provinceData.name);
        this.horizontalBarChartData.push(provinceData.number);
      } else {
        otherAmount = otherAmount + provinceData.number;
      }
    });
    this.horizontalBarChartLabels.push('Otro');
    this.horizontalBarChartData.push(otherAmount);
    this.horizontalBarColors = [
      { backgroundColor: ['#78BE20', '#007749', '#E56A54',  '#F0B323',
        '#F9E1A7', '#005333', '#B3D6C8', '#A1D263'] },
    ];
  }
  showPreprint() {
    this.barChartData.forEach((data) => {
      delete data._meta;
    });
    const canvas = {
      barChartData: this.barChartData,
      barChartLabels: this.barChartLabels,
      barChartOptions: this.barChartOptions,
      barChartLegend: this.barChartLegend,
      barChartType: this.barChartType,
    };
    const canvasGeo = {
      horizontalBarChartData: this.horizontalBarChartData,
      horizontalBarChartLabels: this.horizontalBarChartLabels,
      pieChartType: this.pieChartType,
      pieChartOptions: this.pieChartOptions,
      pieChartPlugins: this.pieChartPlugins,
      pieChartLegend: this.pieChartLegend,
      horizontalBarColors: this.horizontalBarColors,
    };

    localStorage.setItem('reportCanvas', JSON.stringify(canvas));
    localStorage.setItem('reportCanvasGeo', JSON.stringify(canvasGeo));
    localStorage.setItem('inspectionNumber', this.inspections.length);
    const filters = {
      initDate: this.initDate,
      endDate: this.endDate,
      agentCod: this.agentCod,
      vehicleType: this.vehicleType,
      procedureType: this.procedureType,
      minValueSlide: this.minValueSlide,
      maxValueSlide: this.maxValueSlide,
      province: this.zone,
    };
    localStorage.setItem('filtersCanvas', JSON.stringify(filters));
    const filtersGeo = {
      initDate: this.initDateGeo,
      endDate: this.endDateGeo,
      agentCod: this.agentCod,
      vehicleType: this.vehicleTypeGeo,
      procedureType: this.procedureTypeGeo,
      minValueSlide: this.minValueSlideGeo,
      maxValueSlide: this.maxValueSlideGeo,
    };
    localStorage.setItem('filtersCanvasGeo', JSON.stringify(filtersGeo));
    localStorage.setItem('dataSourceData', JSON.stringify(this.dataSource.data));
    const url = this.router.createUrlTree(['previewReport']);
    window.open(url.toString(), '_blank');
  }
}
