import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GobalValidators } from '../../../validators/gobalValidators';
import { ClientApi } from '../../../shared/sdk/services/custom/index';
import { ToastrService } from 'ngx-toastr';
import {  Router } from '@angular/router';

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './user-profile-edit.component.html',
  styleUrls: ['./user-profile-edit.component.scss'],
  // animations: [routerTransition()]
})
export class UserProfileEditComponent implements OnInit {

  myForm: UntypedFormGroup;
  user: any; // Photographer

  id: string;
  submitAttempt: boolean;
  loadingPage: boolean;

  public constructor(
    private formBuilder: UntypedFormBuilder,
    private clientApi:  ClientApi,
    private toastr: ToastrService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.loadingPage = true;
    const userData = JSON.parse(localStorage.getItem('user'));
    this.user = userData;
    this.myForm = this.formBuilder.group({
      email: [{ value: (userData.email) ? userData.email : '', disabled: true },
        Validators.compose([Validators.required, GobalValidators.mailFormat])],
      name: [userData.name, Validators.compose([Validators.maxLength(30),
        Validators.pattern(
          '[\' A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙñÑ.-]*'), Validators.required])],
      webhook: [''],
      apiKey: ['A6ABEA4E2E74F74E77432CB382467', Validators.compose([Validators.maxLength(30)])],
      oldPassword: [''],
      password: [''],
      repeatPassword: [''],
    });
    this.loadingPage = false;
  }
  saveProfile() {
    this.submitAttempt = true;
    if (this.myForm.valid) {
      const jsonClient = {
        name: this.myForm.value.name,
        cellPhone: this.myForm.value.cellPhone,
      };
      this.clientApi.patchClient(this.user.id, jsonClient)
        .subscribe(() => {
          this.toastr.success(
            'Datos actualizados correctamente', 'Guardado',
            {
              positionClass: 'toast-bottom-right',
            });
        },         (err: any) => {
          this.loadingPage = false;
          if (err.statusCode === 401) {
            this.toastr.warning(
              'El usuario y/o contraseña son incorrectos', 'Aviso',
              {
                positionClass: 'toast-bottom-right',
              });
          } else {
            this.toastr.warning(
              'Ocurrio un error al actualizar los datos', 'Aviso',
              {
                positionClass: 'toast-bottom-right',
              });
          }
        });
    }
  }
  changePassword() {
    if (this.myForm.value.password === this.myForm.value.repeatPassword) {
      this.clientApi.changePassword(
        { oldPassword: this.myForm.value.oldPassword,
          newPassword: this.myForm.value.password }).subscribe(
        () => {
          this.toastr.success(
            'Su contraseña ha sido actualizada. Deberá loguearse nuevamente en el sistema.',
            'Contraseña cambiada',
            { positionClass: 'toast-bottom-right',
            });
          this.router.navigate(['login']);
        },
        (err) => {
          if (err.statusCode === 401) {
            this.toastr.error(
              'La contraseña anterior es incorrecta', 'Error',
              { positionClass: 'toast-bottom-right',
              });
          } else {
            this.toastr.error(
              'Ocurrió un error, intente nuevamente más tarde', 'Error',
              { positionClass: 'toast-bottom-right',
              });
          }
        },
      );
    } else {
      this.toastr.error(
        'Las contraseñas no coinciden',
        'Error',
        { positionClass: 'toast-bottom-right',
        });
    }
  }
}
