import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from '../../shared/services/sidebar.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  collapsed = false;
  constructor(public router: Router,
    private sidebarService: SidebarService) {}

  ngOnInit() {
    this.sidebarService.getStatusSidebar().subscribe((status) => {
      if (status === 'close') {
        this.collapsed = true;
      } else {
        this.collapsed = false;
      }
    });
  }
}
