import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import sweetalert2 from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { InspectionService } from '../../../shared/services/inspection.service';
import { RelatedInspectionsModalComponent }
  from './related-inspections-modal/related-inspections-modal.component';
import { HistoricalInspectionApi } from '../../../shared/sdk';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { GoogleMap } from '@angular/google-maps';
import { SmartAnalyzerService } from '../../../shared/services/smart-analyzer-service';
import { MatDialog } from '@angular/material/dialog';
const DEFAULT_ZOOM = 15;

@Component({
  selector: 'app-inspection-detail',
  templateUrl: './inspection-detail.component.html',
  styleUrls: ['./inspection-detail.component.scss'],
})
export class InspectionDetailComponent implements OnInit {

  inspection: any;
  vehicleType;
  bounds: any = false;
  pictures = [];
  inspectionId: string;
  browserSupportsExifRotation;
  relatedInspections;

  disableRetry = false;

  isAdmin;
  apiLoaded: Observable<boolean>;

  mapOptions: google.maps.MapOptions = {
  };

  markerOptions: google.maps.MarkerOptions = { draggable: false };

  @ViewChild('googleMapRef')
    googleMaps: GoogleMap;
  zoom = DEFAULT_ZOOM - 1; // Hack to map zoom works fine

  public radarChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
      align: 'center',
      labels: {
        usePointStyle: true,
        boxWidth: 10,
        padding: 20,
      },
    },
    scale: {
      ticks: {
        maxTicksLimit: 3,
      },
    },
  };
  public radarChartLabels: string[] = [
    'Encuadre', 'Iluminación', 'Vehículo detectado', 'Calidad',
    'Fotografía real',
  ];

  public radarChartData: any = [];
  colors = [
    'rgb(154, 142, 241,0.2)', 'rgb(140, 37, 129,0.2)',
    'rgb(121, 212, 116,0.2)', 'rgb(224, 170, 64,0.2)',
    'rgb(200, 70, 41,0.2)', 'rgb(84, 153, 70,0.2)',
    'rgb(100, 100, 255,0.2)', 'rgb(255, 100, 100,0.2)',
    'rgb(100, 255, 100,0.2)', 'rgb(255, 255, 100,0.2)',
    'rgb(100, 255, 255,0.2)',
  ];

  borderColors = [
    'rgb(154, 142, 241)', 'rgb(140, 37, 129)',
    'rgb(121, 212, 116)', 'rgb(224, 170, 64)',
    'rgb(200, 70, 41)', 'rgb(84, 153, 70)',
    'rgb(100, 100, 255)', 'rgb(255, 100, 100)',
    'rgb(100, 255, 100)', 'rgb(255, 255, 100)',
    'rgb(100, 255, 255)',
  ];

  public radarChartType: string = 'radar';
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public dialog: MatDialog,
    private inspectionService: InspectionService,
    private historicalInspectionApi: HistoricalInspectionApi,
    private toastr: ToastrService,
    public smartAnalyzerService: SmartAnalyzerService,
  ) {
    this.inspectionId = this.route.snapshot.paramMap.get('id');
  }
  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.isAdmin =
      !!user.roles.filter(role => role.name === 'admin' || role.name === 'superadmin').length;
    this.route.params.subscribe(
      (params) => {
        const id = params['id'];
        this.inspectionId = id;
        this.getInspection();
      },
    );



  }
  showChart;
  getInspection() {
    this.inspectionService.getInspection(this.inspectionId).subscribe(
      (inspection) => {
        this.inspection = inspection;
        this.createRadarChart();
        this.loadMap();
        // Only rotate images if chrome version is older than 81
        this.inspectionService.getRelatedIspections(inspection).subscribe((relatedInspections) => {
          this.relatedInspections = relatedInspections;
        });
      },
      () => {
        sweetalert2.fire({
          title: 'Ups! Algo salio mal',
          text: 'Ocurrió un error al acceder a los datos.',
          type: 'error',
          confirmButtonText: 'Salir',
          buttonsStyling: false,
        });
      },
    );
  }
  createRadarChart() {
    this.radarChartData = this.inspection.pictures.map((picture, index) => {
      const colorIndex = index % this.colors.length;
      if (picture.alreadyAnalyzed && picture.analyzeResponse) {
        return {
          data: [
            1 - picture.analyzeResponse?.badFraming,
            1 - picture.analyzeResponse?.badLighting,
            picture.analyzeResponse?.containsACar,
            picture.analyzeResponse?.goodQuality,
            1 - picture.analyzeResponse?.pictureFromScreen,
          ],
          label: `${picture.description}`,
          backgroundColor: this.colors[colorIndex],
          borderColor: this.borderColors[colorIndex],
          borderWidth: 1,
          pointBackgroundColor: this.borderColors[colorIndex],
          pointBorderColor: 'white',
          pointBorderWidth: 2,
          pointRadius: 5,
          pointHoverRadius: 7,
          pointStyle: 'circle',
        };
      }

    }).filter(dataSet => dataSet !== undefined);
    this.showChart = true;
  }
  loadMap() {
    this.bounds = new window['google'].maps.LatLngBounds();
    for (const mm of this.inspection.pictures) {
      this.bounds.extend(
        new window['google'].maps
          .LatLng(mm.location.coordinates[1], mm.location.coordinates[0]));
    }
    setTimeout(() => {
      this.googleMaps.fitBounds(this.bounds, 0);
      const idleSubscription = this.googleMaps.idle.subscribe(() => {
        if (this.googleMaps.getZoom() > DEFAULT_ZOOM) {
          this.zoom = DEFAULT_ZOOM;
        }
        idleSubscription.unsubscribe();
      });
    }, 100);
  }
  searchDamageGlass() {
    if (this.inspection.inspectionForm && this.inspection.inspectionForm.glasses) {
      return this.inspection.inspectionForm.glasses.find(x => x.damaged > 0);
    }
    return false;
  }
  openModalRelated() {
    const options = {
      panelClass: 'modal-dialog',
      data: { relatedInspections: this.relatedInspections },
    };
    const dialogRef = this.dialog.open(RelatedInspectionsModalComponent, options);
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  webhookRetry() {
    this.disableRetry = true;
    this.historicalInspectionApi.resendInspection(this.inspection.id).subscribe(
      () => {
        this.toastr.success(
          'Inspection reenviada',
          '',
          {
            positionClass: 'toast-bottom-right',
          });
        setTimeout(() => {
          this.getInspection();
        }, 2000);
        setTimeout(() => {
          this.disableRetry = false;
        }, 30000);
      },
      (error) => {
        console.error(error);
        this.toastr.error(
          'Error al intentar reenviar la inspección', '',
          {
            positionClass: 'toast-bottom-right',
          });
      },
    );
  }
  parseAnalyzeResponse(picture) {
    const obj = [];
    if (picture.analyzeResponse && picture.analyzeResponse) {

      for (const element in picture.analyzeResponse) {
        if (element === 'badFraming') {
          obj.push({
            name: 'Encuadre',
            negativeName: 'Mal encuadre',
            value: Math.floor((1 - picture.analyzeResponse[element]) * 100),
            class: (1 - picture.analyzeResponse[element]) < (1 - this.smartAnalyzerService.BAD_QUALITY_THRESHOLDS.minimumConfidenceBadFraming) ? `warning-${Math.floor((1 - picture.analyzeResponse[element]) * 100)}` : `light-${Math.floor((1 - picture.analyzeResponse[element]) * 100)}`,
          });
        }
        if (element === 'badLighting') {
          obj.push({
            name: 'Iluminacion',
            negativeName: 'Mala Iluminación',
            value: Math.floor((1 - picture.analyzeResponse[element]) * 100),
            class: 1 - picture.analyzeResponse[element] < 1 - this.smartAnalyzerService.BAD_QUALITY_THRESHOLDS.minimumConfidenceBadLighting ? `warning-${Math.floor((1 - picture.analyzeResponse[element]) * 100)}` : `light-${Math.floor((1 - picture.analyzeResponse[element]) * 100)}`,
          });
        }
        if (element === 'pictureFromScreen') {
          obj.push({
            name: 'fotografía real',
            negativeName: 'fotografía a pantalla',
            value: Math.floor((1 - picture.analyzeResponse[element]) * 100),
            class: 1 - picture.analyzeResponse[element] < 1 - this.smartAnalyzerService.BAD_QUALITY_THRESHOLDS.minimumConfidencePictureFromScreen ? `warning-${Math.floor((1 - picture.analyzeResponse[element]) * 100)}` : `light-${Math.floor((1 - picture.analyzeResponse[element]) * 100)}`,

          });
        }
        if (element === 'containsACar') {
          obj.push({
            name: 'Vehículo detectado',
            negativeName: 'vehículo no detectado',
            value: Math.floor((picture.analyzeResponse[element]) * 100),
            class: picture.analyzeResponse[element] < this.smartAnalyzerService.BAD_QUALITY_THRESHOLDS.minimumConfidenceContainsAcar ? `warning-${Math.floor((picture.analyzeResponse[element]) * 100)}` : `light-${Math.floor((picture.analyzeResponse[element]) * 100)}`,

          });
        }
        if (element === 'goodQuality') {
          obj.push({
            name: 'Calidad',
            negativeName: 'Mala calidad',
            value: Math.floor((picture.analyzeResponse[element]) * 100),
            class: picture.analyzeResponse[element] < this.smartAnalyzerService.BAD_QUALITY_THRESHOLDS.minimumConfidenceBadFraming ? `warning-${Math.floor((picture.analyzeResponse[element]) * 100)}` : `light-${Math.floor((picture.analyzeResponse[element]) * 100)}`,

          });
        }
      }
    }
    return obj;

  }
  showAnalyzeResponse(picture) {
    picture.showAnalyzeResponse = !picture.showAnalyzeResponse;
  }
}
