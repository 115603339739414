import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-related-inspections-modal',
  templateUrl: './related-inspections-modal.component.html',
  styleUrls: ['./related-inspections-modal.component.scss'],
})
export class RelatedInspectionsModalComponent implements OnInit {
  relatedInspections;
  constructor(public dialog: MatDialogRef<RelatedInspectionsModalComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.relatedInspections = this.data.relatedInspections;
  }
  closeModal() {
    this.dialog.close();
  }
}
