import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/user-management/login/login.component';
import { LayoutComponent } from './components/layout/layout.component';
import { InspectionsListComponent } from
  './components/inspecitons/inspections-list/inspections-list.component';
import { InspectionDetailComponent }
  from './components/inspecitons/inspection-detail/inspection-detail.component';
import { UserProfileEditComponent }
  from './components/user-management/user-profile-edit/user-profile-edit.component';
import { AuthGuard } from './shared';
import { InspectionMoreInformationComponent }
  from './components/inspecitons/inspection-more-information/inspection-more-information.component';
import { AnalysisComponent } from './components/analysis/analysis.component';
import { InspectionDetailOnePageComponent } from
  './components/inspecitons/inspection-detail-one-page/inspection-detail-one-page.component';
import { UserListComponent } from './components/user-management/user-list/user-list.component';
import { PreviewReportComponent }
  from './components/analysis/preview-report/preview-report.component';
import { InspectionGeneratorComponent } from './components/inspecitons/inspection-generator/inspection-generator.component';
import { EnvironmentGuard } from './shared/guard/enviroment.guard';

const routes: Routes = [
  { path: '', component: LayoutComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'inspectionsList', pathMatch: 'full' },
      { path: 'inspectionsList', component: InspectionsListComponent },
      { path: 'inspections/:id', component: InspectionDetailComponent },
      { path: 'inspections/:id/pictures/:picName', component: InspectionMoreInformationComponent },
      { path: 'userProfileEdit', component: UserProfileEditComponent },
      { path: 'staticsAnalysis', component: AnalysisComponent },
      { path: 'users', component: UserListComponent,  data: { roles: ['admin', 'superadmin'] } },
      { path: 'create-inspection', component: InspectionGeneratorComponent,  data: { roles: [ 'superadmin'] }, canActivate:[EnvironmentGuard] },
    ],
  },
  { path: 'previewReport', component: PreviewReportComponent },
  { path: 'login', component: LoginComponent },
  { path: 'inspectionsOnePage/:id', component: InspectionDetailOnePageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
